import { Alert, Button, Stack, Typography } from '@mui/material';
import { PasswordField } from 'components/shared/PasswordField';
import { observer } from 'mobx-react-lite';
import { KeyboardEvent } from 'react';
import { ChangePasswordStore } from 'stores/ChangePasswordStore';

type ChangePasswordProps = {
  changePasswordStore: ChangePasswordStore;
};

const ChangePassword = observer(
  ({ changePasswordStore }: ChangePasswordProps) => {
    const { form, message, hasError, changePassword } = changePasswordStore;

    const handleKeyInField = (evt: KeyboardEvent) => {
      if (evt.key === 'Enter') {
        evt.stopPropagation();
        evt.preventDefault();
        if (form.isValid) {
          changePassword();
        }
      }
    };

    return (
      <div
        aria-label="change password page"
        style={{ width: 400, marginTop: 80, marginBottom: 30 }}
      >
        <form autoComplete="off">
          <Stack spacing={4}>
            <Typography variant="h5">
              <strong>Change Password</strong>
            </Typography>
            {message && (
              <Alert
                aria-label="form-error"
                severity={hasError ? 'error' : 'success'}
              >
                {message}
              </Alert>
            )}
            <PasswordField
              variant="outlined"
              autoComplete="current-password"
              onKeyDown={handleKeyInField}
              data-testid="oldPassword"
              {...form.$('oldPassword').bind()}
              inputProps={{
                role: 'textbox',
                'aria-label': 'password',
              }}
            />
            <PasswordField
              variant="outlined"
              autoComplete="new-password"
              onKeyDown={handleKeyInField}
              data-testid="newPassword"
              {...form.$('newPassword').bind()}
              inputProps={{
                role: 'textbox',
                'aria-label': 'password',
              }}
            />
            <PasswordField
              variant="outlined"
              autoComplete="new-password"
              onKeyDown={handleKeyInField}
              data-testid="confirmPassword"
              {...form.$('confirmPassword').bind()}
              inputProps={{
                role: 'textbox',
                'aria-label': 'confirmPassword',
              }}
            />

            <Button
              variant="contained"
              disableElevation
              aria-label="change password"
              style={{ height: 56 }}
              disabled={!form.isValid}
              onClick={() => changePassword()}
            >
              Change Password
            </Button>
          </Stack>
        </form>
      </div>
    );
  },
);

export { ChangePassword };
