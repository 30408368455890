import { UserCreateInput } from 'dtos/UserCreateInput';
import {
  IReactionDisposer,
  makeAutoObservable,
  reaction,
  runInAction,
} from 'mobx';
import { AuthStore } from 'stores/AuthStore';
import { createForm } from 'forms/createUserForm';
import { Plan, Product, Role } from 'dtos/common';
import { Match } from 'navigo';
import { fieldValueToMoment } from 'forms/utils/muiFormBindings';
import { difference, without } from 'lodash';
import { getFeatureFlagValue } from '@bowery-valuation/feature-flagger-client';
import { defaultProducts, featureFlags } from 'utils/config';

export class CreateUserStore {
  private dispose: IReactionDisposer[] = [];
  private authStore: AuthStore;

  form = createForm();
  message = '';
  hasError: boolean = false;
  prevProducts: Product[] = [];

  constructor({ authStore }: { authStore: AuthStore }) {
    this.authStore = authStore;
    makeAutoObservable(this, {}, { autoBind: true });

    this.dispose.push(
      reaction(
        () => this.form.$('planProducts').get('value'),
        (products: Product[]) => {
          const justChecked = difference(products, this.prevProducts)[0];
          if (justChecked === Product.WORLD) {
            this.form.$('planProducts').set('value', [Product.WORLD]);
          } else if (justChecked !== undefined) {
            this.form
              .$('planProducts')
              .set('value', without(products, Product.WORLD));
          }
          this.prevProducts = products;
        },
      ),
    );
  }

  destroy() {
    this.dispose.map((fn) => fn());
  }

  activate(match: Match) {
    this.prevProducts = [];
    this.form.reset();
    this.message = '';
    this.hasError = false;
  }

  async createUser() {
    this.message = '';
    const { isValid } = await this.form.validate({ showErrors: true });
    if (!isValid) {
      return;
    }
    const dto = this.formToDto(this.form.values());
    try {
      const response = await this.authStore.fetchWithAuth(
        `${process.env.REACT_APP_SERVER_URI}/users`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dto),
        },
      );
      if (!response.ok) throw new Error('Error creating user');
      this.prevProducts = [];
      this.form.reset();
      runInAction(() => {
        this.message = 'The user was created.';
        this.hasError = false;
      });
    } catch (err) {
      runInAction(() => {
        this.message = 'Unable to create user.';
        this.hasError = true;
      });
    }
  }

  private formToDto(form: any): UserCreateInput {
    const {
      username,
      password,
      email,
      plan,
      planExpiration,
      planRenews,
      planProducts,
      enabled,
      admin,
      paymentCustomerId,
    } = form;
    const editedRoles: Role[] = [];
    if (enabled) editedRoles.push(Role.USER);
    if (admin) editedRoles.push(Role.ADMIN);
    const planExpirationMoment = fieldValueToMoment(planExpiration);
    let products = getFeatureFlagValue(featureFlags.multipleProducts)
      ? planProducts
      : defaultProducts;
    const dto: UserCreateInput = {
      username,
      password,
      email,
      roles: editedRoles,
      plan: plan === 'none' ? null : plan,
      planExpiration:
        plan !== 'none' && planExpirationMoment
          ? planExpirationMoment.toISOString()
          : null,
      planRenews: plan === Plan.MONTHLY ? planRenews : false,
      planProducts: plan !== 'none' ? products : [],
      paymentCustomerId,
    };
    return dto;
  }
}
