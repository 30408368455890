import { Field } from 'mobx-react-form';
import * as moment from 'moment';

// Terrible hacks to make MUI date picker work with mobx-react-form.
// The picker expects Moment objects in the value/onChange handlers,
// but the form library loses their prototype when trying to store them.
// So we're converting them to UNIX timestamp strings before storing.
// We need a special string to indicate non-empty values, so our custom
// validator will be called.

// Note: Date picker preserves time, unless you clear it first.

export const invalidDate = 'invalid';

export const fieldValueToMoment = (value: any): moment.Moment | null => {
  if (!value || value === invalidDate) return null;
  return moment.unix(value).utc();
};

export const momentToFieldValue = (value: moment.Moment | null): string => {
  if (value) {
    if (!value.isValid()) return invalidDate;
    return '' + value.unix();
  }
  return '';
};

const onChangeMoment = (field: Field) => (value: any) => {
  field.set('value', momentToFieldValue(value ? value.utc(true) : null));
};

const onChangeWithEventAndValue = (field: Field) => (event: any, value: any) =>
  field.set('value', value);

const forceRevalidate = (field: Field) => () => {
  field.onFocus();
  field.onBlur();
};

export const muiFormBindings = {
  TextField: ({ $try, field, props }: any) => ({
    id: $try(props.id, field.id),
    name: $try(props.name, field.name),
    type: $try(props.type, field.type),
    value: $try(props.value, field.value),
    label: $try(props.label, field.label),
    placeholder: $try(props.placeholder, field.placeholder),
    helperText: $try(props.error, field.error),
    error: !!props.error || !!field.error, // hasError doesn't clear at the right times
    disabled: $try(props.disabled, field.disabled),
    onChange: $try(props.onChange, field.onChange),
    onBlur: $try(props.onBlur, field.onBlur),
    onFocus: $try(props.onFocus, field.onFocus),
    autoFocus: $try(props.autoFocus, field.autoFocus),
  }),
  Select: {
    id: 'id',
    name: 'name',
    value: 'value',
    label: 'label',
    disabled: 'disabled',
    onChange: 'onChange',
  },
  Checkbox: {
    id: 'id',
    name: 'name',
    value: 'checked',
    label: 'label',
    disabled: 'disabled',
    onChange: 'onChange',
  },
  CustomToggleButtonGroup: ({ $try, field, props }: any) => {
    return {
      id: $try(props.id, field.id),
      value: $try(props.value, field.value),
      disabled: $try(props.disabled, field.disabled),
      onChange: $try(props.onChange, field.onChange),
    };
  },
  ToggleButtonGroup: ({ $try, field, props }: any) => {
    return {
      id: $try(props.id, field.id),
      value: $try(props.value, field.value),
      disabled: $try(props.disabled, field.disabled),
      onChange: $try(props.onChange, onChangeWithEventAndValue(field)),
    };
  },
  DatePicker: ({ $try, field, props }: any) => {
    return {
      id: $try(props.id, field.id),
      value: fieldValueToMoment($try(props.value, field.value)),
      disabled: $try(props.disabled, field.disabled),
      onChange: $try(props.onChange, onChangeMoment(field)),
      onClose: $try(props.onBlur, forceRevalidate(field)),
      RenderInputProps: {
        id: $try(props.id, field.id),
        name: $try(props.name, field.name),
        label: $try(props.label, field.label),
        // placeholder overridden by format in inputProps
        helperText: $try(props.error, field.error),
        error: !!props.error || !!field.error, // hasError doesn't clear at the right times
        onBlur: $try(props.onBlur, field.onBlur),
        onFocus: $try(props.onFocus, field.onFocus),
        autoFocus: $try(props.autoFocus, field.autoFocus),
      },
    };
  },
};
