import {
  Alert,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { KeyboardEvent } from 'react';
import { JobDetailsStore } from 'stores/JobDetailsStore';

type JobDetailsProps = {
  jobDetailsStore: JobDetailsStore;
};

const JobDetails = observer(({ jobDetailsStore }: JobDetailsProps) => {
  const { job, message, boweryId, setBoweryId, getJobDetails, formValid } =
    jobDetailsStore;

  const handleKeyInField = (evt: KeyboardEvent) => {
    if (evt.key === 'Enter') {
      evt.stopPropagation();
      evt.preventDefault();
      if (formValid) {
        getJobDetails();
      }
    }
  };

  return (
    <div
      aria-label="job details page"
      style={{ width: 500, marginTop: 80, marginBottom: 30 }}
    >
      <form style={{ width: 400 }}>
        <Stack spacing={4}>
          <Typography variant="h5">
            <strong>Job Details</strong>
          </Typography>
          {message && (
            <Alert aria-label="form-error" severity="error">
              {message}
            </Alert>
          )}
          <TextField
            variant="outlined"
            onKeyDown={handleKeyInField}
            data-testid="boweryId"
            label="Bowery ID"
            value={boweryId}
            onChange={(event) => setBoweryId(event.target.value)}
            inputProps={{
              role: 'textbox',
              'aria-label': 'bowery ID',
            }}
          />
          <Button
            variant="contained"
            disableElevation
            aria-label="search"
            style={{ height: 56 }}
            disabled={!formValid}
            onClick={() => getJobDetails()}
          >
            Search
          </Button>
        </Stack>
      </form>

      {job && (
        <Table style={{ marginTop: 32 }}>
          <TableBody>
            <TableRow>
              <TableCell variant="head">Bowery ID</TableCell>
              <TableCell variant="body">{job.boweryId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Job ID</TableCell>
              <TableCell variant="body">{job.jobNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Report ID</TableCell>
              <TableCell variant="body">{job.reportId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Address</TableCell>
              <TableCell variant="body">
                {job.address.streetAddress}
                <br />
                {job.address.city} {job.address.state} {job.address.postalCode}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </div>
  );
});

export { JobDetails };
