import { Email, PhoneInTalk } from '@mui/icons-material';
import { styled, Typography } from '@mui/material';

const Link = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  color: 'inherit',
  ...theme.typography.body1,
}));

const Contact = () => {
  return (
    <div
      aria-label="contact page"
      style={{ width: 500, marginTop: 80, marginBottom: 30 }}
    >
      <Typography variant="h5">
        <strong>Contact Support</strong>
      </Typography>
      <Typography variant="body1" sx={{ marginTop: 1 }}>
        Reach out to us for app support or questions.
      </Typography>
      <div style={{ marginTop: 32, display: 'flex' }}>
        <Email color="primary" sx={{ marginRight: '10px' }} />
        <Link href="mailto:support@boweryvaluation.com">
          support@boweryvaluation.com
        </Link>
      </div>
      <div style={{ marginTop: 16, display: 'flex' }}>
        <PhoneInTalk color="primary" sx={{ marginRight: '10px' }} />
        <Typography variant="body1">(212) 634-9397</Typography>
      </div>
    </div>
  );
};

export { Contact };
