import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
} from '@mui/material';

type CustomToggleButtonGroupProps = {
  items: ({
    key: any;
    value: any;
    text: string;
  } & ButtonProps)[];
  value: any;
  onChange: (value: any) => void;
} & ButtonGroupProps;

const CustomToggleButtonGroup = ({
  items,
  value,
  onChange,
  ...toggleButtonGroupProps
}: CustomToggleButtonGroupProps) => {
  const getVariant = (itemValue: any) => {
    return value === itemValue ? 'contained' : 'outlined';
  };

  return (
    <ButtonGroup {...toggleButtonGroupProps}>
      {items.map(({ key, value: itemValue, text, ...buttonProps }) => (
        <Button
          key={key ?? itemValue}
          variant={getVariant(itemValue)}
          disableElevation
          onClick={() => onChange(itemValue)}
          sx={{ whiteSpace: 'nowrap' }}
          {...buttonProps}
        >
          {text}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export { CustomToggleButtonGroup };
