import {
  InputAdornment,
  StandardTextFieldProps,
  TextField,
} from '@mui/material';
import React from 'react';
import { NumberFormatProps } from 'react-number-format';
import { NumberField } from 'components/shared/NumberField';

type NumberRangeFieldProps = {
  label: string;
  formatterProps?: NumberFormatProps;
  minFieldProps?: StandardTextFieldProps;
  maxFieldProps?: StandardTextFieldProps;
};

export const NumberRangeField = React.memo(
  ({
    label,
    formatterProps,
    minFieldProps,
    maxFieldProps,
  }: NumberRangeFieldProps) => {
    return (
      <TextField
        label={label}
        value="-"
        sx={(theme) => ({
          '&:focus-within .MuiFormLabel-root': {
            color: theme.palette.primary.main,
          },
          '& .MuiOutlinedInput-root': {
            '&:focus-within fieldset': {
              borderColor: theme.palette.primary.main,
              borderWidth: '2px',
            },
            '& > .MuiInputBase-input': {
              width: 12,
            },
          },
        })}
        size="small"
        helperText={minFieldProps?.helperText || maxFieldProps?.helperText}
        inputProps={{ style: { textAlign: 'center' }, tabIndex: -1 }}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <NumberField
                variant="standard"
                size="small"
                {...minFieldProps}
                InputProps={{
                  style: { marginTop: 6 },
                  disableUnderline: true,
                  ...minFieldProps?.InputProps,
                }}
                formatterProps={formatterProps}
                label={undefined}
                helperText={undefined}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <NumberField
                variant="standard"
                size="small"
                {...maxFieldProps}
                InputProps={{
                  style: { marginTop: 6, textAlign: 'right' },
                  disableUnderline: true,
                  ...maxFieldProps?.InputProps,
                }}
                formatterProps={formatterProps}
                label={undefined}
                helperText={undefined}
              />
            </InputAdornment>
          ),
        }}
      />
    );
  },
);
