export enum Role {
  USER = 'user',
  ADMIN = 'admin',
}

export enum Plan {
  DEMO = 'demo',
  MONTHLY = 'monthly',
}

export enum Product {
  WORLD = 'world',
  NEW_YORK = 'newYork',
}
