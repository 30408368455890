import { Plan, Product } from 'dtos/common';
import * as moment from 'moment';

export function formatProduct(product: Product): string {
  switch (product) {
    case Product.WORLD:
      return 'Nationwide';
    case Product.NEW_YORK:
      return 'New York';
  }
}

export function formatPlan(plan: Plan | null): string {
  switch (plan) {
    case Plan.DEMO:
      return 'Trial';
    case Plan.MONTHLY:
      return 'Monthly';
  }
  return 'None';
}

export function formatAppraisalDate(appraisalDateBucket: {
  month: number;
  year: number;
}): string {
  const { month, year } = appraisalDateBucket;
  return moment.utc({ day: 1, month: month - 1, year }).format('MMM YYYY');
}

export function formatGrossBuildingAreaBucket(
  grossBuildingAreaBucket: number,
): string {
  switch (grossBuildingAreaBucket) {
    case 0:
      return '1 - 4,000';
    case 4001:
      return '4,001-10,000';
    case 10001:
      return '10,001-20,000';
    case 20001:
      return '20,001-40,000';
    case 40001:
      return '40,001-70,000';
    case 70001:
      return '70,001-120,000';
    case 120001:
      return '>120,000';
    default:
      return '';
  }
}

export function formatUnitsBucket(unitsBucket: number): string {
  switch (unitsBucket) {
    case 0:
      return '1-4';
    case 5:
      return '5-10';
    case 11:
      return '11-20';
    case 21:
      return '21-40';
    case 41:
      return '41-70';
    case 71:
      return '71-120';
    case 121:
      return '>120';
    default:
      return '';
  }
}

export function formatYearBuiltBucket(yearBuiltBucket: number): string {
  switch (yearBuiltBucket) {
    case 0:
      return 'Before 1940';
    case 1940:
      return '1940-1980';
    case 1981:
      return '1981-2000';
    case 2001:
      return 'After 2000';
    default:
      return '';
  }
}
