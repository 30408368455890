import { Alert, Button, Chip, lighten, Stack, Typography } from '@mui/material';
import { Plan } from 'dtos/common';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { AuthStore } from 'stores/AuthStore';
import { PaymentsStore } from 'stores/PaymentsStore';
import { useFeatureFlag } from '@bowery-valuation/feature-flagger-client';
import { featureFlags } from 'utils/config';
import { formatPlan, formatProduct } from 'utils/formatters';
import { usePaymentLinks } from 'components/usePaymentLinks';

type PaymentsProps = {
  authStore: AuthStore;
  paymentsStore: PaymentsStore;
};

const Payments = observer(({ authStore, paymentsStore }: PaymentsProps) => {
  const { message, hasError } = paymentsStore;
  const { currentUser, isAdmin } = authStore;
  const {
    username,
    email,
    plan,
    planExpiration,
    planExpired,
    planRenews,
    planProducts,
    hasPayments,
  } = currentUser!;

  const { disableButtons, renderMenu, handleClickSignup, handleClickManage } =
    usePaymentLinks();

  const multipleProducts = useFeatureFlag(featureFlags.multipleProducts);

  return (
    <div
      aria-label="payments page"
      style={{ width: 400, marginTop: 80, marginBottom: 30 }}
    >
      <form autoComplete="off">
        <Stack spacing={4}>
          <Typography variant="h5">
            <strong>Manage Plan</strong>
          </Typography>
          {message && (
            <Alert
              aria-label="form-error"
              severity={hasError ? 'error' : 'success'}
            >
              {message}
            </Alert>
          )}
          <Stack spacing={2}>
            <Typography variant="body1">
              <strong>Username: </strong>
              {username}
            </Typography>
            <Typography variant="body1">
              <strong>Email: </strong>
              {email}
            </Typography>
            {!isAdmin && (
              <>
                <Typography variant="body1">
                  <strong>Plan Type: </strong>
                  {formatPlan(plan)}
                </Typography>
                {plan && multipleProducts && (
                  <Typography variant="body1">
                    <strong>Product: </strong>
                    {planProducts.map(formatProduct).join(', ')}
                  </Typography>
                )}
                {plan && (
                  <Typography variant="body1" component="div">
                    <strong>
                      {plan === Plan.DEMO
                        ? 'Trial Expires: '
                        : planRenews && !planExpired
                        ? 'Plan Renews: '
                        : 'Plan Expires: '}
                    </strong>
                    {planExpiration
                      ? moment.utc(planExpiration).format('MMMM D, YYYY')
                      : 'None'}
                    {planExpired && (
                      <Chip
                        sx={{
                          marginLeft: '11px',
                          lineHeight: 1,
                          backgroundColor: (theme) =>
                            lighten(theme.palette.error.light, 0.9),
                        }}
                        label="Expired"
                      />
                    )}
                  </Typography>
                )}
              </>
            )}
          </Stack>
          {!isAdmin && (
            <Stack spacing={2}>
              {(plan !== Plan.MONTHLY || planExpired) && (
                <>
                  <Button
                    variant="contained"
                    disabled={disableButtons}
                    onClick={handleClickSignup}
                    sx={{ height: '56px' }}
                  >
                    {plan === Plan.MONTHLY ? 'Renew Plan' : 'Upgrade Plan'}
                  </Button>
                  {renderMenu()}
                </>
              )}
              {hasPayments && (
                <Button
                  sx={{ height: '56px' }}
                  variant="outlined"
                  disabled={disableButtons}
                  onClick={handleClickManage}
                >
                  Manage Plan
                </Button>
              )}
            </Stack>
          )}
        </Stack>
      </form>
    </div>
  );
});

export { Payments };
