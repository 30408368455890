import * as React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import { throttle } from 'throttle-debounce';
import { Paper } from '@mui/material';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Point } from 'stores/MapStore';
import { defaultGooglePlaceOptions } from 'utils/config';

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}
export interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}

async function getCoordsFromAddress(place: PlaceType): Promise<Point | null> {
  try {
    const address = place.description;
    const results = await geocodeByAddress(address);
    const latLng = await getLatLng(results[0]);
    return {
      latitude: latLng.lat,
      longitude: latLng.lng,
    };
  } catch (err) {
    return null;
  }
}

type GoogleAutoCompleteProps = {
  value: PlaceType | null;
  setValue: (newValue: PlaceType | null) => void;
  inputValue: string;
  setInputValue: (newInputValue: string) => void;
  onSelect: (point: Point | null) => void;
  TextFieldProps: TextFieldProps;
};

export function GoogleAutoComplete({
  value,
  setValue,
  inputValue,
  setInputValue,
  onSelect,
  TextFieldProps,
}: GoogleAutoCompleteProps) {
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);

  const fetch = React.useMemo(
    () =>
      throttle(
        200,
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void,
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            {
              types: ['geocode'],
              ...defaultGooglePlaceOptions,
              ...request,
            },
            callback,
          );
        },
      ),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      popupIcon={null}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No address found"
      PaperComponent={(props) => (
        <Paper
          {...props}
          sx={(theme) => ({
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
            '& .MuiAutocomplete-noOptions': {
              ...theme.typography.body2,
            },
          })}
        />
      )}
      onChange={async (event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (newValue) {
          const coords = await getCoordsFromAddress(newValue);
          onSelect(coords);
        } else {
          onSelect(null);
        }
      }}
      isOptionEqualToValue={(option, value) =>
        option.description === value.description
      }
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...TextFieldProps}
          InputProps={{
            ...params.InputProps,
            ...TextFieldProps.InputProps,
            style: {
              paddingRight: 35,
            },
          }}
          inputProps={{
            ...params.inputProps,
            ...TextFieldProps.inputProps,
            autoComplete: 'new-password',
          }}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Typography
              data-testid="search-result"
              variant="body2"
              color="text.primary"
            >
              {option.structured_formatting.main_text}{' '}
              {option.structured_formatting.secondary_text}
            </Typography>
          </li>
        );
      }}
    />
  );
}
