import {
  styled,
  Alert,
  Button,
  Container,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Header } from 'components/Header';
import { observer } from 'mobx-react-lite';
import { KeyboardEvent as ReactKeyboardEvent } from 'react';
import { AuthStore } from 'stores/AuthStore';
import { RouteName } from 'stores/RootStore';

type LoginPageProps = {
  setRoute: (route: RouteName) => void;
  authStore: AuthStore;
};

const LinkButton = styled('button')(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  textTransform: 'initial',
  font: 'inherit',
  fontSize: 'inherit',
  display: 'inline',
  margin: 0,
  padding: 0,
  color: theme.palette.primary.main,
}));

const LoginPage = observer(({ setRoute, authStore }: LoginPageProps) => {
  const { form, error, login } = authStore;

  const handleKeyInField = (evt: ReactKeyboardEvent) => {
    if (evt.key === 'Enter') {
      evt.stopPropagation();
      evt.preventDefault();
      if (form.isValid) {
        login();
      }
    }
  };

  return (
    <div
      aria-label="login page"
      style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
    >
      <Header />
      <Container
        maxWidth="xs"
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <form>
          <Stack spacing={4}>
            <Typography variant="h5" textAlign="center">
              <strong>Log In</strong>
            </Typography>
            {error && (
              <Alert
                aria-label="login-error"
                severity="error"
                style={{ marginBottom: -16 }}
              >
                {error}
              </Alert>
            )}
            <TextField
              variant="outlined"
              label="Username"
              autoComplete="username"
              onKeyDown={handleKeyInField}
              {...form.$('username').bind()}
              inputProps={{
                role: 'textbox',
                'aria-label': 'username',
              }}
            />
            <TextField
              variant="outlined"
              label="Password"
              autoComplete="current-password"
              onKeyDown={handleKeyInField}
              {...form.$('password').bind()}
              inputProps={{
                role: 'textbox',
                'aria-label': 'password',
              }}
            />
            <Button
              variant="contained"
              disableElevation
              aria-label="login"
              disabled={!form.isValid}
              style={{ height: 56 }}
              onClick={() => login()}
            >
              Log In
            </Button>
            <Typography
              variant="body1"
              alignSelf="center"
              color="text.secondary"
              style={{ marginTop: 16 }}
            >
              By continuing you agree to Bowery's{' '}
              <LinkButton onClick={() => setRoute('terms')}>
                Terms of Use
              </LinkButton>
            </Typography>
            <Typography
              variant="body1"
              alignSelf="center"
              color="text.secondary"
              style={{ marginTop: 16 }}
            >
              Need help?{' '}
              <LinkButton onClick={() => setRoute('loginContact')}>
                Contact Support
              </LinkButton>
            </Typography>
          </Stack>
        </form>
      </Container>
    </div>
  );
});

export { LoginPage };
