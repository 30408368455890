import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  styled,
} from '@mui/material';
import { MapStore } from 'stores/MapStore';
import { observer } from 'mobx-react-lite';
import { NumberRangeField } from 'components/shared/NumberRangeField';
import { Refresh, Search } from '@mui/icons-material';
import { CheckboxSelect } from 'components/shared/CheckboxSelect';
import { filters } from 'utils/labels';
import { GoogleAutoComplete } from 'components/shared/GoogleAutoComplete';
import { Select } from 'components/shared/Select';

type FiltersProps = {
  mapStore: MapStore;
};

const Block = styled('div')({
  display: 'inline-flex',
  flexDirection: 'column',
  alignContent: 'initial',
  justifyContent: 'flex-end',
  paddingBottom: 12,
  paddingRight: 8,
});

const BlockWithInput = styled(Block)({
  paddingTop: 12,
  width: 180,
});

const Filters = observer(({ mapStore }: FiltersProps) => {
  const { filtersForm } = mapStore;

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          padding: '12px 24px',
        }}
      >
        <BlockWithInput sx={{ width: '360px' }}>
          <GoogleAutoComplete
            value={mapStore.searchValue}
            setValue={mapStore.setSearchValue}
            inputValue={mapStore.searchInputValue}
            setInputValue={mapStore.setSearchInputValue}
            onSelect={mapStore.searchCoords}
            TextFieldProps={{
              variant: 'outlined',
              label: filters.searchAddress,
              size: 'small',
              inputProps: {
                'aria-label': 'address search',
              },
              InputProps: {
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{ marginLeft: 8, width: 15 }}
                  >
                    <Search />
                  </InputAdornment>
                ),
              },
            }}
          />
        </BlockWithInput>
        <BlockWithInput>
          <FormControl fullWidth size="small">
            <InputLabel>{filters.propertyType}</InputLabel>
            <CheckboxSelect
              name="propertyType"
              data-testid="propertyType"
              {...filtersForm.$('propertyType').bind()}
              items={[
                { value: 'multifamily', text: 'Multifamily' },
                { value: 'mixed-use', text: 'Mixed Use' },
              ]}
            />
          </FormControl>
        </BlockWithInput>
        <BlockWithInput>
          <FormControl fullWidth size="small">
            <InputLabel>{filters.buildingType}</InputLabel>
            <CheckboxSelect
              name="buildingType"
              data-testid="buildingType"
              {...filtersForm.$('buildingType').bind()}
              items={[
                { value: 'walk-up', text: 'Walk-up' },
                { value: 'elevator', text: 'Elevator' },
              ]}
            />
          </FormControl>
        </BlockWithInput>
        <BlockWithInput>
          <FormControl fullWidth size="small">
            <InputLabel>{filters.appraisedWithin}</InputLabel>
            <Select
              name="appraisedWithin"
              data-testid="appraisedWithin"
              {...filtersForm.$('appraisedWithin').bind()}
              items={[
                { value: '3m', text: 'Last 3 Months' },
                { value: '6m', text: 'Last 6 Months' },
                { value: '1y', text: 'Last Year' },
                { value: '1.5y', text: 'Last 1.5 Years' },
                { value: '2y', text: 'Last 2 Years' },
                { value: '3y', text: 'Last 3 Years' },
              ]}
            />
          </FormControl>
        </BlockWithInput>
        <BlockWithInput>
          <FormControl fullWidth size="small">
            <InputLabel>{filters.units}</InputLabel>
            <CheckboxSelect
              name="units"
              data-testid="units"
              {...filtersForm.$('units').bind()}
              items={[
                { value: '0-4', text: '1 - 4 Units' },
                { value: '5-10', text: '5 - 10 Units' },
                { value: '11-20', text: '11 - 20 Units' },
                { value: '21-40', text: '21 - 40 Units' },
                { value: '41-70', text: '41 - 70 Units' },
                { value: '71-120', text: '71 - 120 Units' },
                { value: '121-', text: '>120 Units' },
              ]}
            />
          </FormControl>
        </BlockWithInput>
        <BlockWithInput>
          <FormControl fullWidth size="small">
            <InputLabel>{filters.grossBuildingArea}</InputLabel>
            <CheckboxSelect
              name="grossBuildingArea"
              data-testid="grossBuildingArea"
              {...filtersForm.$('grossBuildingArea').bind()}
              items={[
                { value: '0-4000', text: '1 - 4,000 SF' },
                { value: '4001-10000', text: '4,001 - 10,000 SF' },
                { value: '10001-20000', text: '10,001 - 20,000 SF' },
                { value: '20001-40000', text: '20,001 - 40,000 SF' },
                { value: '40001-70000', text: '40,001 - 70,000 SF' },
                { value: '70001-120000', text: '70,001 - 120,000 SF' },
                { value: '120001-', text: '>120,000 SF' },
              ]}
            />
          </FormControl>
        </BlockWithInput>
        <BlockWithInput>
          <NumberRangeField
            label={filters.capRate}
            minFieldProps={{
              'data-testid': 'capRate.min',
              ...filtersForm.$('capRate.min').bind(),
              InputProps: {
                inputProps: {
                  role: 'textbox',
                  'aria-label': 'min cap rate',
                },
              },
            }}
            maxFieldProps={{
              'data-testid': 'capRate.max',
              ...filtersForm.$('capRate.max').bind(),
              InputProps: {
                inputProps: {
                  role: 'textbox',
                  'aria-label': 'max cap rate',
                },
              },
            }}
            formatterProps={{
              isAllowed: ({ floatValue }) =>
                floatValue === undefined || floatValue < 100,
              allowNegative: false,
              decimalScale: 2,
              fixedDecimalScale: true,
              suffix: '%',
            }}
          />
        </BlockWithInput>
        <BlockWithInput>
          <FormControl fullWidth size="small">
            <InputLabel>{filters.yearBuilt}</InputLabel>
            <CheckboxSelect
              name="yearBuilt"
              data-testid="yearBuilt"
              {...filtersForm.$('yearBuilt').bind()}
              items={[
                { value: '-1939', text: 'Before 1940' },
                { value: '1940-1980', text: '1940 - 1980' },
                { value: '1981-2000', text: '1981 - 2000' },
                { value: '2001-', text: 'After 2000' },
              ]}
            />
          </FormControl>
        </BlockWithInput>
        <BlockWithInput sx={{ width: '190px' }}>
          <FormControl fullWidth size="small">
            <InputLabel>{filters.recentlyRenovated}</InputLabel>
            <CheckboxSelect
              name="recentlyRenovated"
              data-testid="recentlyRenovated"
              {...filtersForm.$('recentlyRenovated').bind()}
              items={[
                { value: 'yes', text: 'Recently Renovated' },
                { value: 'no', text: 'Not Renovated' },
              ]}
            />
          </FormControl>
        </BlockWithInput>
        <Block>
          <Button
            role="button"
            aria-label="reset all"
            endIcon={<Refresh />}
            onClick={mapStore.resetForm}
          >
            Reset All
          </Button>
        </Block>
      </div>
    </div>
  );
});

export { Filters };
