import { styled, Tooltip, Typography } from '@mui/material';
import { JobOutput } from 'dtos/JobOutput';
import numbro from 'numbro';
import {
  formatAppraisalDate,
  formatGrossBuildingAreaBucket,
  formatUnitsBucket,
  formatYearBuiltBucket,
} from 'utils/formatters';
import { ReactComponent as RecentlyAdded } from 'images/recentlyAdded.svg';
import { ReactComponent as MixedUse } from 'images/propertyType/mixedUse.svg';
import { ReactComponent as Multifamily } from 'images/propertyType/multifamily.svg';
import { ReactComponent as WalkUp } from 'images/buildingType/walkUp.svg';
import { ReactComponent as Elevator } from 'images/buildingType/elevator.svg';
import { ReactComponent as Renovated } from 'images/renovated.svg';
import { isRecentlyAdded } from 'utils/job';

type ResultCardProps = {
  selected: boolean;
  onSelect: (boweryId: string) => void;
  displayUnits: 'sf' | 'units';
  job: JobOutput;
  style?: any;
};

const Card = styled('span')(({ theme }) => ({
  overflow: 'hidden',
  border: '1px solid',
  borderColor: 'rgba(215, 222, 233, 1)',
  borderRadius: 4,
  padding: '16px 8px 16px 16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover': {
    border: `1px solid ${theme.palette.text.primary}`,
  },
  '&.selected': {
    border: `2px solid ${theme.palette.primary.main}`,
    padding: '15px 7px 15px 15px', // make up for border
  },
}));

const Column = styled('div')({
  minWidth: 0,
  flex: 1,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const IdBadge = styled('span')({
  backgroundColor: 'rgba(245, 245, 245, 1)',
  borderRadius: 4,
  padding: '2px 8px',
  marginRight: '10px',
  display: 'inline-flex',
  alignItems: 'center',
  '&.recent': {
    backgroundColor: 'rgba(255, 195, 85, 0.2)',
  },
});

const TypographyWithIcon = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    marginRight: 5,
    position: 'relative',
    top: -1,
  },
}) as typeof Typography;

const ResultCard = ({
  selected,
  onSelect,
  displayUnits,
  job,
  style,
}: ResultCardProps) => {
  const recent = isRecentlyAdded(job);
  return (
    <Card
      aria-label="result card"
      className={selected ? 'selected' : ''}
      style={style}
      onClick={() => onSelect(job.boweryId)}
    >
      <Column style={{ flex: 1.1 }}>
        <TypographyWithIcon
          variant="caption"
          component="div"
          sx={{ height: '28px' }}
        >
          {recent ? (
            <Tooltip
              placement="top"
              title="Added this month"
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -10],
                    },
                  },
                ],
              }}
            >
              <IdBadge className="recent">
                <RecentlyAdded
                  style={{
                    color: 'rgba(229, 148, 35, 1)',
                  }}
                />
                Bowery ID: {job.boweryId}
              </IdBadge>
            </Tooltip>
          ) : (
            <IdBadge>Bowery ID: {job.boweryId}</IdBadge>
          )}
        </TypographyWithIcon>

        <TypographyWithIcon
          variant="caption"
          component="div"
          style={{
            marginTop: 3,
            marginLeft: 8,
          }}
        >
          {job.propertyInformation.propertyType === 'mixed-use' && (
            <>
              <MixedUse />
              Mixed Use
            </>
          )}
          {job.propertyInformation.propertyType === 'multifamily' && (
            <>
              <Multifamily />
              Multifamily
            </>
          )}
        </TypographyWithIcon>
      </Column>

      <Column style={{ flex: 1.1 }}>
        {displayUnits === 'sf' ? (
          <Typography variant="h6" component="div" sx={{ fontWeight: 700 }}>
            {numbro(job.appraisalInformation.valuePerSFBucket).formatCurrency({
              thousandSeparated: true,
            })}
            /SF
          </Typography>
        ) : (
          <Typography variant="h6" component="div" sx={{ fontWeight: 700 }}>
            {numbro(job.appraisalInformation.valuePerUnitBucket).formatCurrency(
              { thousandSeparated: true },
            )}
            /unit
          </Typography>
        )}
        <Typography variant="subtitle1" component="div" sx={{ fontSize: 20 }}>
          {numbro(job.appraisalInformation.capRate).format({
            mantissa: 2,
            output: 'percent',
          })}{' '}
          Cap Rate
        </Typography>
      </Column>

      <Column>
        <Typography variant="caption" component="div">
          <strong>
            {formatGrossBuildingAreaBucket(
              job.propertyInformation.grossBuildingAreaBucket,
            )}
          </strong>{' '}
          SF
        </Typography>
        <Typography variant="caption" component="div">
          <strong>
            {formatUnitsBucket(job.propertyInformation.unitsBucket)}
          </strong>{' '}
          Units
        </Typography>
        <Typography variant="caption" component="div">
          <strong>
            {formatYearBuiltBucket(job.propertyInformation.yearBuiltBucket)}
          </strong>{' '}
          Year Built
        </Typography>
      </Column>

      <Column style={{ flex: 1.1 }}>
        {displayUnits === 'sf' ? (
          <Typography variant="caption" component="div">
            <strong>
              {numbro(job.appraisalInformation.pgiPerSFBucket).formatCurrency({
                mantissa: 2,
                thousandSeparated: true,
              })}
            </strong>{' '}
            PGI/SF
          </Typography>
        ) : (
          <Typography variant="caption" component="div">
            <strong>
              {numbro(
                job.appraisalInformation.pgiPerUnitPerMonthBucket,
              ).formatCurrency({ mantissa: 2, thousandSeparated: true })}
            </strong>{' '}
            PGI/unit (monthly)
          </Typography>
        )}

        <Typography variant="caption" component="div">
          <strong>
            {numbro(job.appraisalInformation.expenseRatioBucket).format({
              mantissa: 2,
              output: 'percent',
            })}
          </strong>{' '}
          Expense Ratio
        </Typography>

        <Typography variant="caption" component="div">
          <strong>
            {formatAppraisalDate(job.appraisalInformation.appraisalDateBucket)}
          </strong>{' '}
          Date of Value
        </Typography>
      </Column>

      <Column style={{ flex: 0.9 }}>
        <TypographyWithIcon variant="caption" component="div">
          {job.propertyInformation.buildingType === 'elevator' && (
            <>
              <Elevator />
              Elevatored
            </>
          )}
          {job.propertyInformation.buildingType === 'walk-up' && (
            <>
              <WalkUp />
              Walk-up
            </>
          )}
        </TypographyWithIcon>
        <TypographyWithIcon variant="caption" component="div">
          {job.appraisalInformation.valueConclusionType === 'as-is' ? (
            'Not Renovated'
          ) : (
            <>
              <Renovated />
              Recently Renovated
            </>
          )}
        </TypographyWithIcon>
      </Column>
    </Card>
  );
};

export { ResultCard };
