import { TextField } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';

type CustomDatePickerProps<TInputDate, TDate = TInputDate> = {
  RenderInputProps: any;
} & DatePickerProps<TInputDate, TDate>;

const CustomDatePicker = <TInputDate, TDate = TInputDate>(
  props: CustomDatePickerProps<TInputDate, TDate>,
) => {
  return (
    <DatePicker
      {...props}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props.RenderInputProps}
          InputProps={{
            ...params.InputProps,
            ...props.RenderInputProps?.InputProps,
          }}
        />
      )}
    />
  );
};

export { CustomDatePicker as DatePicker };
