import Form from 'mobx-react-form';
import { muiFormBindings } from 'forms/utils/muiFormBindings';
import { plugins } from 'forms/utils/formPlugins';
import { FieldsDefinitions } from 'mobx-react-form/lib/models/FormInterface';
import { filters } from 'utils/labels';

const fields: FieldsDefinitions = [
  {
    name: 'propertyType',
    label: filters.propertyType,
    bindings: 'Select',
    value: [],
  },
  {
    name: 'buildingType',
    label: filters.buildingType,
    bindings: 'Select',
    value: [],
  },
  {
    name: 'appraisedWithin',
    label: filters.appraisedWithin,
    bindings: 'Select',
    value: '3y',
  },
  {
    name: 'units',
    label: filters.units,
    bindings: 'Select',
    value: [],
  },
  {
    name: 'grossBuildingArea',
    label: filters.grossBuildingArea,
    bindings: 'Select',
    value: [],
  },
  {
    name: 'yearBuilt',
    label: filters.yearBuilt,
    bindings: 'Select',
    value: [],
  },
  {
    name: 'recentlyRenovated',
    label: filters.recentlyRenovated,
    bindings: 'Select',
    value: [],
  },
  {
    name: 'capRate',
    fields: [
      {
        name: 'min',
        bindings: 'TextField',
      },
      {
        name: 'max',
        bindings: 'TextField',
      },
    ],
  },
  {
    name: 'displayUnits',
    label: filters.displayUnits,
    bindings: 'CustomToggleButtonGroup',
    value: 'sf',
  },
];

const hooks = {};

const formOptions = {
  validationDebounceWait: 0,
};

export function createForm() {
  return new Form(
    { fields },
    { plugins, hooks, bindings: muiFormBindings, options: formOptions },
  );
}
