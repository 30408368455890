import { ThemeOptions } from '@mui/material';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgba(66, 96, 211, 1)',
      light: 'rgba(178, 195, 254, 1)',
    },
    error: {
      main: 'rgba(211, 65, 65, 1)',
      light: 'rgba(211, 65, 65, 1)',
    },
    text: {
      secondary: 'rgba(78, 86, 100, 1)',
    },
    warning: {
      main: 'rgba(255, 195, 85, 1)',
    },
  },
  typography: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontWeightMedium: 700,
  },
  components: {
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        },
      },
    },
  },
} as ThemeOptions);

export { theme };
