import { Typography } from '@mui/material';
import { ResultCard } from 'components/map/ResultCard';
import { CustomToggleButtonGroup } from 'components/shared/CustomToggleButtonGroup';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { AutoSizer, List } from 'react-virtualized';
import { MapStore } from 'stores/MapStore';
import { maxJobResults } from 'utils/config';

type ResultsProps = {
  style?: any;
  mapStore: MapStore;
};

const ROW_HEIGHT = 98;

const getNumResults = (num: number): string => {
  if (num === maxJobResults) return `${num}+ Results`;
  if (num === 1) return '1 Result';
  if (num === 0) return 'No Results';
  return `${num} Results`;
};

const Results = observer(({ style = {}, mapStore }: ResultsProps) => {
  const {
    filtersForm,
    results,
    selectedBoweryId,
    selectedBoweryIdFrom,
    selectBoweryIdFromResults,
  } = mapStore;

  const list = useRef<List>(null);

  useEffect(() => {
    list.current?.scrollToRow(0);
  }, [results]);

  useEffect(() => {
    if (selectedBoweryId === null || selectedBoweryIdFrom === 'results') return;
    const rowIndex = results.findIndex(
      (job) => job.boweryId === selectedBoweryId,
    );
    if (rowIndex !== -1) list.current?.scrollToRow(rowIndex);
  }, [results, selectedBoweryId, selectedBoweryIdFrom]);

  return (
    <div
      aria-label="results list"
      style={{ ...style, display: 'flex', flexDirection: 'column' }}
    >
      <div
        style={{
          padding: '12px 16px',
          display: 'flex',
          alignItems: 'center',
          borderBottom: '1px solid rgba(215, 222, 233, 1)',
        }}
      >
        <Typography variant="h6" style={{ flex: 1 }}>
          {getNumResults(results.length)}
        </Typography>
        <CustomToggleButtonGroup
          color="primary"
          {...filtersForm.$('displayUnits').bind()}
          items={[
            { value: 'sf', text: 'SF' },
            { value: 'units', text: 'Units' },
          ]}
        />
      </div>
      <div style={{ flex: 1 }}>
        <AutoSizer>
          {({ height, width }) => {
            return (
              <List
                ref={list}
                width={width}
                height={height}
                rowCount={results.length}
                rowHeight={({ index }) =>
                  index === 0 || index === results.length - 1
                    ? ROW_HEIGHT + 8
                    : ROW_HEIGHT
                }
                scrollToAlignment="start"
                rowRenderer={({ index, key, style }) => {
                  const job = results[index];
                  return (
                    <div
                      key={key}
                      style={{
                        ...style,
                        display: 'flex',
                        minHeight: 0,
                        padding: `${index === 0 ? '16px' : '8px'} 16px ${
                          index === results.length - 1 ? '16px' : '0px'
                        } 16px`,
                      }}
                    >
                      <ResultCard
                        selected={selectedBoweryId === job.boweryId}
                        onSelect={selectBoweryIdFromResults}
                        style={{ flex: 1 }}
                        displayUnits={filtersForm
                          .$('displayUnits')
                          .get('value')}
                        job={job}
                      />
                    </div>
                  );
                }}
              />
            );
          }}
        </AutoSizer>
      </div>
    </div>
  );
});

export { Results };
