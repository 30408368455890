import {
  CheckBox,
  CheckBoxOutlineBlank,
  Delete,
  Edit,
} from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Role } from 'dtos/common';
import { UserAdminOutput } from 'dtos/UserAdminOutput';
import { UserOutput } from 'dtos/UserOutput';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useState } from 'react';
import { RouteName } from 'stores/RootStore';
import { UserListStore } from 'stores/UserListStore';
import { formatPlan } from 'utils/formatters';

type UserListProps = {
  currentUser: UserOutput | null;
  userListStore: UserListStore;
  setRoute: (route: RouteName, params?: any) => void;
};

const UserList = observer(
  ({ currentUser, userListStore, setRoute }: UserListProps) => {
    const [confirmDeleteUser, setConfirmDeleteUser] =
      useState<UserAdminOutput | null>(null);
    const { users, deleteUser, downloadUsers } = userListStore;

    const confirmDelete = (user: UserAdminOutput) => () => {
      setConfirmDeleteUser(user);
    };

    const handleDeleteUserClick = (user: UserAdminOutput) => () => {
      setConfirmDeleteUser(null);
      deleteUser(user);
    };

    return (
      <div
        aria-label="edit users page"
        style={{ width: 600, marginTop: 80, marginBottom: 30 }}
      >
        <Stack spacing={4}>
          <div style={{ display: 'flex' }}>
            <Typography variant="h5">
              <strong>Edit Users</strong>
            </Typography>
            <Button
              onClick={downloadUsers}
              size="small"
              sx={{ marginLeft: '30px' }}
            >
              Download
            </Button>
          </div>
          <Table sx={{ whiteSpace: 'nowrap' }}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ position: 'sticky', left: 0, background: 'white' }}
                  variant="head"
                >
                  Username
                </TableCell>
                <TableCell variant="head">Email</TableCell>
                <TableCell variant="head">Last Access</TableCell>
                <TableCell variant="head">Plan</TableCell>
                <TableCell variant="head">Expires</TableCell>
                <TableCell variant="head" align="center">
                  Enabled
                </TableCell>
                <TableCell variant="head" align="center">
                  Admin
                </TableCell>
                <TableCell variant="head" align="center">
                  Edit
                </TableCell>
                <TableCell variant="head" align="center">
                  Delete
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.username}>
                  <TableCell
                    sx={{ position: 'sticky', left: 0, background: 'white' }}
                    variant="body"
                  >
                    {user.username}
                  </TableCell>
                  <TableCell variant="body">{user.email}</TableCell>
                  <TableCell variant="body">
                    {user.lastAccess &&
                      moment.utc(user.lastAccess).format('YYYY-MM-DD HH:mm') +
                        ' UTC'}
                  </TableCell>
                  <TableCell variant="body">{formatPlan(user.plan)}</TableCell>
                  <TableCell variant="body">
                    {user.planExpiration &&
                      moment
                        .utc(user.planExpiration)
                        .format('YYYY-MM-DD HH:mm') + ' UTC'}
                  </TableCell>
                  <TableCell variant="body" align="center">
                    {user.roles.includes(Role.USER) ? (
                      <CheckBox color="disabled" />
                    ) : (
                      <CheckBoxOutlineBlank color="disabled" />
                    )}
                  </TableCell>
                  <TableCell variant="body" align="center">
                    {user.roles.includes(Role.ADMIN) ? (
                      <CheckBox color="disabled" />
                    ) : (
                      <CheckBoxOutlineBlank color="disabled" />
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() =>
                        setRoute('editUser', { username: user.username })
                      }
                    >
                      <Edit />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    {user.username !== currentUser?.username && (
                      <IconButton onClick={confirmDelete(user)}>
                        <Delete />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Stack>

        {confirmDeleteUser && (
          <Dialog
            open
            onClose={() => setConfirmDeleteUser(null)}
            aria-label="confirm delete"
          >
            <DialogContent>
              <DialogContentText>
                Delete user {confirmDeleteUser.username}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={() => setConfirmDeleteUser(null)}>
                Cancel
              </Button>
              <Button onClick={handleDeleteUserClick(confirmDeleteUser)}>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    );
  },
);

export { UserList };
