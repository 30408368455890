import { JobDetailsOutput } from 'dtos/JobDetailsOutput';
import { makeAutoObservable, runInAction } from 'mobx';
import { AuthStore } from 'stores/AuthStore';
import { Match } from 'navigo';

export class JobDetailsStore {
  private authStore: AuthStore;

  message: string = '';
  boweryId: string = '';
  job: JobDetailsOutput | null = null;

  constructor({ authStore }: { authStore: AuthStore }) {
    this.authStore = authStore;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async activate(match: Match) {
    this.message = '';
    this.boweryId = '';
    this.job = null;
  }

  async getJobDetails() {
    try {
      this.message = '';
      this.job = null;
      const response = await this.authStore.fetchWithAuth(
        `${
          process.env.REACT_APP_SERVER_URI
        }/jobs/details?boweryId=${encodeURIComponent(this.boweryId)}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      if (!response.ok) throw new Error('Error getting job details');
      const job: JobDetailsOutput = await response.json();
      runInAction(() => {
        this.job = job;
      });
    } catch (err) {
      runInAction(() => {
        this.job = null;
        this.message = 'Job could not be found.';
      });
    }
  }

  setBoweryId(boweryId: string) {
    this.boweryId = boweryId;
  }

  get formValid() {
    return this.boweryId.length === 8;
  }
}
