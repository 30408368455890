import { invalidDate } from 'forms/utils/muiFormBindings';
import dvr from 'mobx-react-form/lib/validators/DVR';
import validatorjs from 'validatorjs';

const rules: any = {
  moment: {
    function: (value: any) => value !== invalidDate,
    message: 'The :attribute field must be a date.',
  },
};

export const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator, form }: any) => {
      Object.keys(rules).forEach((key: any) =>
        validator.register(key, rules[key].function, rules[key].message),
      );
    },
  }),
};
