import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

export type NumberFieldProps = {
  formatterProps?: NumberFormatProps;
} & TextFieldProps;

const NumberFormatInput = React.forwardRef((props: any, ref) => (
  <NumberFormat getInputRef={ref} {...props} />
));

export const NumberField = React.memo(
  ({ formatterProps, ...otherProps }: NumberFieldProps) => {
    return (
      <TextField
        {...otherProps}
        InputProps={{
          ...otherProps.InputProps,
          inputComponent: NumberFormatInput,
          inputProps: {
            ...otherProps.InputProps?.inputProps,
            ...formatterProps,
          },
        }}
      />
    );
  },
);
