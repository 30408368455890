import {
  Alert,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { CheckboxSelect } from 'components/shared/CheckboxSelect';
import { DatePicker } from 'components/shared/DatePicker';
import { PasswordField } from 'components/shared/PasswordField';
import { Plan, Product } from 'dtos/common';
import { observer } from 'mobx-react-lite';
import { KeyboardEvent, useEffect } from 'react';
import { CreateUserStore } from 'stores/CreateUserStore';
import { createUser as createUserLabels } from 'utils/labels';
import { useFeatureFlag } from '@bowery-valuation/feature-flagger-client';
import { featureFlags } from 'utils/config';

type CreateUserProps = {
  scrollToTop: () => void;
  createUserStore: CreateUserStore;
};

const CreateUser = observer(
  ({ scrollToTop, createUserStore }: CreateUserProps) => {
    const { form, message, hasError, createUser } = createUserStore;
    const multipleProducts = useFeatureFlag(featureFlags.multipleProducts);

    useEffect(() => {
      if (message) scrollToTop();
    }, [message, scrollToTop]);

    const handleKeyInField = (evt: KeyboardEvent) => {
      if (evt.key === 'Enter') {
        evt.stopPropagation();
        evt.preventDefault();
        if (form.isValid) {
          createUser();
        }
      }
    };

    const plan = form.$('plan').get('value');

    return (
      <div
        aria-label="create user page"
        style={{ width: 400, marginTop: 80, marginBottom: 30 }}
      >
        <form autoComplete="off">
          <Stack spacing={4}>
            <Typography variant="h5">
              <strong>Create User</strong>
            </Typography>
            {message && (
              <Alert
                aria-label="form-error"
                severity={hasError ? 'error' : 'success'}
              >
                {message}
              </Alert>
            )}
            <TextField
              variant="outlined"
              autoComplete="off"
              onKeyDown={handleKeyInField}
              data-testid="username"
              required
              {...form.$('username').bind()}
              inputProps={{
                role: 'textbox',
                'aria-label': 'username',
              }}
            />
            <PasswordField
              variant="outlined"
              autoComplete="new-password"
              onKeyDown={handleKeyInField}
              data-testid="password"
              required
              {...form.$('password').bind()}
              inputProps={{
                role: 'textbox',
                'aria-label': 'password',
              }}
            />
            <PasswordField
              variant="outlined"
              autoComplete="new-password"
              onKeyDown={handleKeyInField}
              data-testid="confirmPassword"
              required
              {...form.$('confirmPassword').bind()}
              inputProps={{
                role: 'textbox',
                'aria-label': 'confirmPassword',
              }}
            />
            <TextField
              variant="outlined"
              autoComplete="off"
              onKeyDown={handleKeyInField}
              data-testid="email"
              required
              {...form.$('email').bind()}
              inputProps={{
                role: 'textbox',
                'aria-label': 'email',
              }}
            />
            <TextField
              select
              required
              name="plan"
              data-testid="plan"
              {...form.$('plan').bind()}
            >
              <MenuItem value="none">None</MenuItem>
              <MenuItem value={Plan.DEMO}>Demo</MenuItem>
              <MenuItem value={Plan.MONTHLY}>Paid: Monthly</MenuItem>
            </TextField>
            {plan !== 'none' && (
              <DatePicker
                {...form.$('planExpiration').bind()}
                variant="outlined"
                autoComplete="off"
                onKeyDown={handleKeyInField}
                data-testid="planExpiration"
                inputFormat="YYYY-MM-DD"
                inputProps={{
                  role: 'textbox',
                  'aria-label': 'plan expiration',
                }}
              />
            )}
            {plan === Plan.MONTHLY && (
              <Tooltip
                title="Plan active, and user has not cancelled"
                placement="top-start"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      data-testid="planRenews"
                      {...form.$('planRenews').bind()}
                    />
                  }
                  label={createUserLabels.planRenews}
                />
              </Tooltip>
            )}
            {plan !== 'none' && multipleProducts && (
              <FormControl>
                <InputLabel>{createUserLabels.planProducts}</InputLabel>
                <CheckboxSelect
                  data-testid="planProducts"
                  {...form.$('planProducts').bind()}
                  items={[
                    { value: Product.WORLD, text: 'Nationwide' },
                    { value: Product.NEW_YORK, text: 'New York' },
                  ]}
                />
              </FormControl>
            )}
            <TextField
              variant="outlined"
              autoComplete="off"
              onKeyDown={handleKeyInField}
              data-testid="paymentCustomerId"
              {...form.$('paymentCustomerId').bind()}
              inputProps={{
                role: 'textbox',
                'aria-label': 'payment customer ID',
              }}
            />
            <Tooltip title="User can log in" placement="top-start">
              <FormControlLabel
                control={
                  <Checkbox
                    data-testid="enabled"
                    {...form.$('enabled').bind()}
                  />
                }
                label={createUserLabels.enabled}
              />
            </Tooltip>
            <Tooltip
              title="User can edit other users and view job details"
              placement="top-start"
            >
              <FormControlLabel
                control={
                  <Checkbox data-testid="admin" {...form.$('admin').bind()} />
                }
                label={createUserLabels.admin}
              />
            </Tooltip>

            <Button
              variant="contained"
              disableElevation
              aria-label="create user"
              style={{ height: 56 }}
              disabled={!form.isValid}
              onClick={() => createUser()}
            >
              Create User
            </Button>
          </Stack>
        </form>
      </div>
    );
  },
);

export { CreateUser };
