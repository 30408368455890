import { observer } from 'mobx-react-lite';
import { useRootStore } from 'stores/RootStore';
import { SecondaryPage } from 'components/SecondaryPage';
import { Contact } from 'components/contact/Contact';

const ContactPage = observer(() => {
  const { routeStore, authStore } = useRootStore();
  const { route, setRoute } = routeStore;
  const { isLoggedIn } = authStore;

  return (
    <SecondaryPage
      escapeKeyBack={true}
      route={route}
      setRoute={setRoute}
      backRoute={isLoggedIn ? 'search' : 'login'}
      renderBody={() => <Contact />}
    />
  );
});

export { ContactPage };
