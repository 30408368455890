import { styled, Button, Typography, ButtonBase, Alert } from '@mui/material';
import { ReactComponent as BoweryLogo } from 'images/bowery.svg';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'stores/RootStore';
import { useFeatureFlag } from '@bowery-valuation/feature-flagger-client';
import { featureFlags } from 'utils/config';
import { Plan } from 'dtos/common';
import { usePaymentLinks } from 'components/usePaymentLinks';

const Toolbar = styled('div')({
  borderBottom: '1px solid rgba(215, 222, 233, 1)',
  height: 64,
  padding: '8px 12px',
  display: 'flex',
  alignItems: 'center',
});

const LinkButton = styled(Button)(({ theme }) => ({
  lineHeight: '24px',
  marginLeft: theme.spacing(4),
}));

const Header = observer(() => {
  const { authStore, routeStore } = useRootStore();
  const { route, setRoute } = routeStore;
  const {
    isAdmin,
    logout,
    isLoggedIn,
    warnPlanExpiring,
    planExpiringInDays,
    currentUser,
  } = authStore;
  const allowPayments = useFeatureFlag(featureFlags.payments);
  const { plan, planExpired } = currentUser || {};

  const { disableButtons, renderMenu, handleClickSignup } = usePaymentLinks();

  const logoRoute =
    isLoggedIn && route !== 'search'
      ? 'search'
      : !isLoggedIn && route !== 'login'
      ? 'login'
      : undefined;

  const logo = (
    <>
      <BoweryLogo style={{ marginLeft: 11, marginRight: 30, height: '100%' }} />
      <Typography variant="h6" style={{ fontWeight: 400 }}>
        <strong>Sizer</strong> by Bowery
      </Typography>
    </>
  );

  return (
    <>
      <Toolbar aria-label="header">
        {logoRoute ? (
          <ButtonBase onClick={() => setRoute(logoRoute)}>{logo}</ButtonBase>
        ) : (
          logo
        )}
        <div style={{ flex: 1 }}></div>
        {isAdmin && (
          <LinkButton aria-label="admin" onClick={() => setRoute('createUser')}>
            Admin
          </LinkButton>
        )}
        {isLoggedIn && (
          <LinkButton
            aria-label="contact support"
            onClick={() => setRoute('contact')}
          >
            Contact Support
          </LinkButton>
        )}
        {isLoggedIn && (
          <LinkButton
            aria-label="change password"
            onClick={() => setRoute('changePassword')}
          >
            Change Password
          </LinkButton>
        )}
        {isLoggedIn && allowPayments && (
          <LinkButton
            aria-label="payments"
            onClick={() => setRoute('payments')}
          >
            Manage Plan
          </LinkButton>
        )}
        {isLoggedIn && (
          <LinkButton aria-label="logout" onClick={() => logout()}>
            Log Out
          </LinkButton>
        )}
        {!isLoggedIn && route !== 'login' && (
          <LinkButton aria-label="login" onClick={() => setRoute('login')}>
            Log In
          </LinkButton>
        )}
      </Toolbar>
      {(warnPlanExpiring || (planExpired && route !== 'search')) && (
        <Alert
          severity={warnPlanExpiring ? 'warning' : 'error'}
          action={
            <>
              <Button
                disabled={disableButtons}
                onClick={handleClickSignup}
                color="inherit"
                size="small"
              >
                {plan === Plan.MONTHLY ? 'Renew Plan' : 'Upgrade Plan'}
              </Button>
              {renderMenu()}
            </>
          }
        >
          Your {plan === Plan.MONTHLY ? 'monthly plan' : 'free trial'}
          {planExpired
            ? ' has expired. '
            : ` is expiring in ${planExpiringInDays} ${
                planExpiringInDays === 1 ? 'day. ' : 'days. '
              }`}
          {plan === Plan.MONTHLY ? 'Renew' : 'Upgrade'} your plan to keep using
          Sizer.
        </Alert>
      )}
    </>
  );
});

export { Header };
