import { DivIcon, Point, PointTuple } from 'leaflet';
import { Marker, Popup, Tooltip } from 'react-leaflet';
import { memo, ReactNode } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { ReactComponent as SearchAddress } from 'images/searchAddress.svg';
import { ReactComponent as Pin } from 'images/pin.svg';
import { ReactComponent as PinSelected } from 'images/pinSelected.svg';
import './LeafletMarker.css';

const POPUP_OPTIONS = {
  maxWidth: 370,
  minWidth: 370,
  offset: new Point(14, 0),
};

export type IconName = 'search' | 'job' | 'jobSelected';

export type LeafletMarkerProps = {
  id: string;
  alt: string;
  position: {
    lat: number;
    lng: number;
  };
  icon: IconName;
  popup?: ReactNode;
  tooltip?: ReactNode;
  onClick?: (id: string) => void;
  displayUnits: 'sf' | 'units';
};

function propsAreEqual(
  prevProps: LeafletMarkerProps,
  nextProps: LeafletMarkerProps,
) {
  return (
    prevProps.position.lat === nextProps.position.lat &&
    prevProps.position.lng === nextProps.position.lng &&
    prevProps.displayUnits === nextProps.displayUnits &&
    prevProps.icon === nextProps.icon
  );
}

const icons = {
  search: renderToStaticMarkup(<SearchAddress />),
  job: renderToStaticMarkup(<Pin fontSize="large" />),
  jobSelected: renderToStaticMarkup(<PinSelected fontSize="large" />),
};

const iconAnchors: { [key: string]: PointTuple } = {
  search: [20, 52],
  job: [11, 30],
  jobSelected: [11, 30],
};

const tooltipAnchors: { [key: string]: PointTuple } = {
  search: [0, -53],
  job: [0, -31],
  jobSelected: [0, -31],
};

const LeafletMarker = memo(
  ({
    id,
    alt,
    position,
    icon,
    popup,
    tooltip,
    onClick,
  }: LeafletMarkerProps) => {
    return (
      <Marker
        position={position}
        icon={
          new DivIcon({
            className: icon === 'jobSelected' ? ' selected' : '',
            html: `<div aria-label="${alt}">${icons[icon]}</div>`,
            iconAnchor: iconAnchors[icon],
            tooltipAnchor: tooltipAnchors[icon],
          })
        }
        riseOnHover
        riseOffset={100}
        zIndexOffset={icon === 'jobSelected' ? 50 : 0}
        eventHandlers={onClick ? { click: (e) => onClick(id) } : undefined}
      >
        {popup && (
          <Popup position={position} {...POPUP_OPTIONS}>
            {popup}
          </Popup>
        )}
        {tooltip && <Tooltip direction="top">{tooltip}</Tooltip>}
      </Marker>
    );
  },
  propsAreEqual,
);

export { LeafletMarker };
