import {
  Checkbox,
  ListItemText,
  MenuItem,
  MenuItemProps,
  Select,
  SelectProps,
  Tooltip,
} from '@mui/material';

type CheckboxSelectProps = {
  items: ({
    key?: any;
    value: any;
    text: string;
  } & MenuItemProps)[];
  value: any[];
} & SelectProps;

const CheckboxSelect = ({
  value,
  items,
  ...selectProps
}: CheckboxSelectProps) => {
  const renderValue = (value: any) => {
    if (!Array.isArray(value)) return '';
    const selectedItems = items.filter((item) => value.includes(item.value));
    const rendered = selectedItems.map((item) => item.text).join(', ');
    return (
      <Tooltip placement="top-start" title={rendered}>
        <span>{rendered}</span>
      </Tooltip>
    );
  };

  return (
    <Select multiple value={value} renderValue={renderValue} {...selectProps}>
      {items.map(({ key, value: itemValue, text, ...itemProps }) => (
        <MenuItem key={key ?? itemValue} value={itemValue} {...itemProps}>
          <Checkbox checked={value.indexOf(itemValue) > -1} />
          <ListItemText primary={text} />
        </MenuItem>
      ))}
    </Select>
  );
};

export { CheckboxSelect };
