import { Role } from 'dtos/common';
import { UserAdminOutput } from 'dtos/UserAdminOutput';
import { UserListOutput } from 'dtos/UserListOutput';
import { makeAutoObservable, runInAction } from 'mobx';
import { AuthStore } from 'stores/AuthStore';
import { Match } from 'navigo';
import { formatPlan, formatProduct } from 'utils/formatters';
import { unparse } from 'papaparse';
import { createUser } from 'utils/labels';

export class UserListStore {
  private authStore: AuthStore;

  users: UserAdminOutput[] = [];

  constructor({ authStore }: { authStore: AuthStore }) {
    this.authStore = authStore;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async activate(match: Match) {
    await this.getUsers();
  }

  async getUsers() {
    try {
      const response = await this.authStore.fetchWithAuth(
        `${process.env.REACT_APP_SERVER_URI}/users`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      if (!response.ok) throw new Error('Error getting users');
      const { users }: UserListOutput = await response.json();
      runInAction(() => {
        this.users = users.sort((user1, user2) =>
          user1.username.localeCompare(user2.username),
        );
      });
    } catch (err) {
      runInAction(() => {
        this.users = [];
      });
    }
  }

  async deleteUser(user: UserAdminOutput) {
    try {
      const response = await this.authStore.fetchWithAuth(
        `${process.env.REACT_APP_SERVER_URI}/users/${encodeURIComponent(
          user.username,
        )}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: this.authStore.getAuthHeader(),
          },
        },
      );
      if (!response.ok) throw new Error('Error deleting user');
      await this.getUsers();
    } catch (err) {}
  }

  downloadUsers = () => {
    const data = this.users.map((user) => [
      user.username,
      user.email,
      user.roles.includes(Role.USER) ? 'Y' : 'N',
      user.roles.includes(Role.ADMIN) ? 'Y' : 'N',
      user.lastAccess ? new Date(user.lastAccess).toISOString() : '',
      formatPlan(user.plan),
      user.plan && user.planExpiration
        ? new Date(user.planExpiration).toISOString()
        : '',
      user.planRenews ? 'Y' : 'N',
      user.planProducts.map(formatProduct).join(', '),
      user.paymentCustomerId,
    ]);
    const fields = [
      createUser.username,
      createUser.email,
      createUser.enabled,
      createUser.admin,
      'Last Access',
      createUser.plan,
      createUser.planExpiration,
      createUser.planRenews,
      createUser.planProducts,
      createUser.paymentCustomerId,
    ];

    const csv = unparse({ fields, data });
    if (csv == null) return;

    const filename = 'users.csv';

    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(csvData);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
}
