export function getNumberFromString(value: string): number | undefined {
  if (value === '') return undefined;
  const number = Number(value);
  return isNaN(number) ? undefined : number;
}

export function getNumberFromPercentString(value: string): number | undefined {
  if (value === '') return undefined;
  value = value.replace(/%/g, '');
  const number = Number(value);
  return isNaN(number) ? undefined : number / 100;
}

export function getNumberSearchRange(value: string) {
  const parts = value.split('-');
  return {
    min: getNumberFromString(parts[0]),
    max: getNumberFromString(parts[1]),
  };
}
