import { ChangePasswordInput } from 'dtos/ChangePasswordInput';
import { makeAutoObservable, runInAction } from 'mobx';
import { AuthStore } from 'stores/AuthStore';
import { createForm } from 'forms/changePasswordForm';
import { Match } from 'navigo';

export class ChangePasswordStore {
  private authStore: AuthStore;

  form = createForm();
  message = '';
  hasError: boolean = false;

  constructor({ authStore }: { authStore: AuthStore }) {
    this.authStore = authStore;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  activate(match: Match) {
    this.form.reset();
    this.message = '';
    this.hasError = false;
  }

  async changePassword() {
    this.message = '';
    const { isValid } = await this.form.validate({ showErrors: true });
    if (!isValid) {
      return;
    }
    const { oldPassword, newPassword } = this.form.values();
    const dto: ChangePasswordInput = {
      oldPassword,
      newPassword,
    };
    try {
      const response = await this.authStore.fetchWithAuth(
        `${process.env.REACT_APP_SERVER_URI}/users/password`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dto),
        },
      );
      if (!response.ok) throw new Error('Error changing password');
      this.form.reset();
      runInAction(() => {
        this.message = 'Your password was changed.';
        this.hasError = false;
      });
    } catch (err) {
      runInAction(() => {
        this.message = 'Unable to change password. Please try again.';
        this.hasError = true;
      });
    }
  }
}
