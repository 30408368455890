import { Filters } from 'components/filters/Filters';
import { Header } from 'components/Header';
import { MapAndResults } from 'components/map/MapAndResults';
import { observer } from 'mobx-react-lite';
import { MapStore } from 'stores/MapStore';
import { AuthStore } from 'stores/AuthStore';
import { ExpiredOverlay } from 'components/ExpiredOverlay';

type SearchPageProps = {
  mapStore: MapStore;
  authStore: AuthStore;
};

const SearchPage = observer(({ mapStore, authStore }: SearchPageProps) => {
  const { currentUser, isAdmin } = authStore;
  const { planExpired } = currentUser || {};

  return (
    <div
      aria-label="search page"
      style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
    >
      <Header />
      <div
        style={{
          flex: 1,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Filters mapStore={mapStore} />
        <MapAndResults style={{ minHeight: 0, flex: 1 }} mapStore={mapStore} />
        {!isAdmin && planExpired && currentUser && (
          <ExpiredOverlay user={currentUser} />
        )}
      </div>
    </div>
  );
});

export { SearchPage };
