import { observer } from 'mobx-react-lite';
import { CreateUser } from 'components/admin/CreateUser';
import { useRootStore } from 'stores/RootStore';
import { UserList } from 'components/admin/UserList';
import { JobDetails } from 'components/admin/JobDetails';
import { EditUser } from 'components/admin/EditUser';
import { Link, SecondaryPage } from 'components/SecondaryPage';

const links: Link[] = [
  {
    route: 'createUser',
    text: 'Create User',
  },
  {
    route: 'editUsers',
    otherRoutes: ['editUser'],
    text: 'Edit Users',
  },
  {
    route: 'jobDetails',
    text: 'Job Details',
  },
];

const AdminPage = observer(() => {
  const {
    authStore,
    routeStore,
    createUserStore,
    editUserStore,
    userListStore,
    jobDetailsStore,
  } = useRootStore();
  const { route, setRoute } = routeStore;
  const { currentUser } = authStore;

  return (
    <SecondaryPage
      links={links}
      route={route}
      setRoute={setRoute}
      backRoute={route === 'editUser' ? 'editUsers' : 'search'}
      renderBody={({ scrollToTop }) => (
        <>
          {route === 'createUser' && (
            <CreateUser
              scrollToTop={scrollToTop}
              createUserStore={createUserStore}
            />
          )}
          {route === 'editUsers' && (
            <UserList
              currentUser={currentUser}
              setRoute={setRoute}
              userListStore={userListStore}
            />
          )}
          {route === 'editUser' && (
            <EditUser scrollToTop={scrollToTop} editUserStore={editUserStore} />
          )}
          {route === 'jobDetails' && (
            <JobDetails jobDetailsStore={jobDetailsStore} />
          )}
        </>
      )}
    />
  );
});

export { AdminPage };
