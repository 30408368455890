import Form from 'mobx-react-form';
import { muiFormBindings } from 'forms/utils/muiFormBindings';
import { plugins } from 'forms/utils/formPlugins';
import { FieldsDefinitions } from 'mobx-react-form/lib/models/FormInterface';
import { changePassword } from 'utils/labels';

const fields: FieldsDefinitions = [
  {
    name: 'oldPassword',
    label: changePassword.oldPassword,
    bindings: 'TextField',
    type: 'password',
    value: '',
    rules: 'required|string',
  },
  {
    name: 'newPassword',
    label: changePassword.newPassword,
    bindings: 'TextField',
    type: 'password',
    value: '',
    rules: 'required|string|between:8,100',
  },
  {
    name: 'confirmPassword',
    label: changePassword.confirmPassword,
    bindings: 'TextField',
    type: 'password',
    value: '',
    rules: 'required|same:newPassword',
  },
];

const hooks = {};

const formOptions = {
  validationDebounceWait: 0,
  showErrorsOnReset: false,
};

export function createForm() {
  return new Form(
    { fields },
    { plugins, hooks, bindings: muiFormBindings, options: formOptions },
  );
}
