import { MapStore } from 'stores/MapStore';
import { reaction, makeAutoObservable } from 'mobx';
import { createContext, useContext } from 'react';
import { AuthStore } from 'stores/AuthStore';
import { UserListStore } from 'stores/UserListStore';
import { CreateUserStore } from 'stores/CreateUserStore';
import { JobDetailsStore } from 'stores/JobDetailsStore';
import { ChangePasswordStore } from 'stores/ChangePasswordStore';
import { EditUserStore } from 'stores/EditUserStore';
import { PaymentsStore } from 'stores/PaymentsStore';
import Navigo, { Match, BeforeHook } from 'navigo';
import { RouteStore } from 'stores/RouteStore';

export type RouteName =
  | 'login'
  | 'loginContact'
  | 'terms'
  | 'search'
  | 'contact'
  | 'createUser'
  | 'editUsers'
  | 'editUser'
  | 'jobDetails'
  | 'changePassword'
  | 'payments'
  | 'paymentsSuccess';

export class RootStore {
  router: Navigo;
  routeStore: RouteStore<RouteName>;
  authStore: AuthStore;
  paymentsStore!: PaymentsStore;
  mapStore!: MapStore;
  createUserStore!: CreateUserStore;
  editUserStore!: EditUserStore;
  userListStore!: UserListStore;
  jobDetailsStore!: JobDetailsStore;
  changePasswordStore!: ChangePasswordStore;

  constructor() {
    this.router = new Navigo('/');
    this.routeStore = new RouteStore(this.router);
    this.router.notFound(() => {
      console.error('Route not found');
      this.routeStore.setRoute('search');
    });

    this.authStore = new AuthStore();
    this.initAuthenticatedStores();

    makeAutoObservable(this, {}, { autoBind: true });
  }

  async init() {
    this.setupRoutes();
    await this.authStore.checkAuth();
    if (document.location.pathname === '/') {
      this.routeStore.setRoute(
        this.authStore.status === 'valid' ? 'search' : 'login',
      );
    }
    this.router.resolve();

    reaction(
      () => this.authStore.status,
      (authStatus) => {
        if (authStatus === 'valid') {
          this.initAuthenticatedStores();
          this.routeStore.setRoute('search');
        } else if (authStatus === 'invalid') {
          this.routeStore.setRoute('login');
        }
      },
    );
  }

  setupRoutes() {
    const requireNoSession: BeforeHook = (done) => {
      if (this.authStore.isLoggedIn) {
        console.error('Page requires no session');
        this.routeStore.setRoute('search');
        done(false);
      } else {
        done();
      }
    };

    const requireSession: BeforeHook = (done) => {
      if (!this.authStore.isLoggedIn) {
        console.error('Page requires session');
        this.routeStore.setRoute('login');
        done(false);
      } else {
        done();
      }
    };

    const requireAdmin: BeforeHook = (done) => {
      if (!this.authStore.isAdmin) {
        console.error('Page requires admin user');
        this.routeStore.setRoute('search');
        done(false);
      } else {
        done();
      }
    };

    this.router.on({
      login: {
        uses: () => {},
        hooks: { before: requireNoSession },
      },
      loginContact: {
        uses: () => {},
        hooks: { before: requireNoSession },
      },
      terms: {
        uses: () => {},
        hooks: { before: requireNoSession },
      },
      search: {
        uses: (match: Match) => this.mapStore?.activate(match),
        hooks: { before: requireSession },
      },
      contact: {
        uses: () => {},
        hooks: { before: requireSession },
      },
      changePassword: {
        uses: (match: Match) => this.changePasswordStore?.activate(match),
        hooks: { before: requireSession },
      },
      payments: {
        uses: (match: Match) => this.paymentsStore?.activate(match),
        hooks: { before: requireSession },
      },
      'payments/success': {
        as: 'paymentsSuccess',
        uses: (match: Match) =>
          this.paymentsStore?.activatePaymentSuccess(match),
        hooks: { before: requireSession },
      },
      createUser: {
        uses: (match: Match) => this.createUserStore?.activate(match),
        hooks: { before: requireAdmin },
      },
      editUsers: {
        uses: (match: Match) => this.userListStore?.activate(match),
        hooks: { before: requireAdmin },
      },
      'editUser/:username': {
        as: 'editUser',
        uses: (match: Match) => this.editUserStore?.activate(match),
        hooks: { before: requireAdmin },
      },
      jobDetails: {
        uses: (match: Match) => this.jobDetailsStore?.activate(match),
        hooks: { before: requireAdmin },
      },
    });
  }

  private initAuthenticatedStores() {
    if (this.mapStore) this.mapStore.destroy();
    if (this.createUserStore) this.createUserStore.destroy();
    if (this.editUserStore) this.editUserStore.destroy();

    this.paymentsStore = new PaymentsStore({
      authStore: this.authStore,
      setRoute: this.routeStore.setRoute,
    });
    this.mapStore = new MapStore({ authStore: this.authStore });
    this.createUserStore = new CreateUserStore({ authStore: this.authStore });
    this.userListStore = new UserListStore({ authStore: this.authStore });
    this.editUserStore = new EditUserStore({
      authStore: this.authStore,
      userListStore: this.userListStore,
      setRoute: this.routeStore.setRoute,
    });
    this.jobDetailsStore = new JobDetailsStore({ authStore: this.authStore });
    this.changePasswordStore = new ChangePasswordStore({
      authStore: this.authStore,
    });
  }
}

export const RootStoreContext = createContext<RootStore>({} as RootStore);

export const useRootStore = (): RootStore => useContext(RootStoreContext);
