import { ArrowBack } from '@mui/icons-material';
import { styled, ButtonBase, Button } from '@mui/material';
import { Header } from 'components/Header';
import { useEffect, useRef } from 'react';
import { RouteName } from 'stores/RootStore';

const NavLink = styled(ButtonBase)(({ theme }) => ({
  height: '50px',
  width: '100%',
  textAlign: 'left',
  padding: '5px 12px',
  display: 'block',
  borderRadius: 4,
  '&.selected': {
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
  },
}));

export type Link = {
  route: RouteName;
  otherRoutes?: RouteName[];
  text: string;
};

type RenderBodyProps = {
  scrollToTop: () => void;
};

type SecondaryPageProps = {
  escapeKeyBack?: boolean;
  links?: Link[];
  route: RouteName | null;
  setRoute: (route: RouteName) => void;
  backRoute: RouteName;
  renderBody: ({ scrollToTop }: RenderBodyProps) => JSX.Element;
};

const SecondaryPage = ({
  escapeKeyBack = false,
  backRoute,
  links = [],
  route,
  setRoute,
  renderBody,
}: SecondaryPageProps) => {
  const scroller = useRef<HTMLDivElement>(null);

  const scrollToTop = () => {
    scroller.current?.scrollTo(0, 0);
  };

  const setRouteAndScrollToTop = (route: RouteName) => {
    setRoute(route);
    scrollToTop();
  };

  useEffect(() => {
    const handleKey = (evt: KeyboardEvent) => {
      if (evt.key === 'Escape') setRoute(backRoute);
    };
    if (escapeKeyBack) {
      document.addEventListener('keydown', handleKey);
      return () => document.removeEventListener('keydown', handleKey);
    }
  }, [escapeKeyBack, backRoute, setRoute]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Header />
      <div
        style={{
          flex: 1,
          minHeight: 0,
          display: 'flex',
          position: 'relative',
        }}
      >
        <div aria-label="sidebar" style={{ width: 150, marginRight: 50 }}>
          <Button
            sx={{ margin: '30px' }}
            onClick={() => setRouteAndScrollToTop(backRoute)}
            variant="text"
            startIcon={<ArrowBack />}
          >
            Back
          </Button>
          {links.map((link) => (
            <NavLink
              key={link.route}
              onClick={() => setRouteAndScrollToTop(link.route)}
              className={
                link.route === route ||
                (route && link.otherRoutes?.includes(route))
                  ? 'selected'
                  : 'unselected'
              }
            >
              {link.text}
            </NavLink>
          ))}
        </div>
        <div
          ref={scroller}
          aria-label="main"
          style={{
            flex: 1,
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {renderBody({ scrollToTop })}
        </div>
      </div>
    </div>
  );
};

export { SecondaryPage };
