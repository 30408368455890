import { styled, Typography } from '@mui/material';

const Container = styled('div')(({ theme }) => ({
  width: '800px',
  marginTop: 80,
  marginBottom: 40,
  '& p': {
    marginTop: theme.spacing(2),
  },
  '& h6': {
    marginTop: theme.spacing(3),
  },
}));

export const Terms = () => {
  return (
    <Container data-testid="terms-page">
      <Typography variant="h5">
        <strong>Terms of Use</strong>
      </Typography>

      <Typography variant="body1">
        This Master Subscription Agreement (“Agreement”) is a legal agreement
        between Bowery Real Estate Systems, Inc. (“Bowery”) and Customer,
        governing Customer&#8217;s access to and use of Bowery Services. If
        Customer is using Bowery Services or creating an account on behalf of an
        entity, then “Customer” includes Customer and that entity, and Customer
        represents and warrants that the individual accepting this Agreement is
        an authorized representative of the entity with the authority to bind
        the entity to this Agreement.
      </Typography>

      <Typography variant="body1">
        By checking the box indicating Customer&#8217;s acceptance of this
        Agreement, Customer acknowledges that: (a) Customer has read and
        understood this Agreement; and (b) Customer is legally competent to
        enter and agree to this Agreement. If Customer does not check the box
        indicating acceptance of the terms of this Agreement, Customer may not
        access or use Bowery Services.
      </Typography>

      <Typography variant="body1">
        THIS AGREEMENT INCLUDES (1) AN ARBITRATION PROVISION AND (2) A WAIVER OF
        RIGHTS TO BRING A CLASS ACTION AGAINST BOWERY
      </Typography>

      <Typography variant="h6">1. DEFINITIONS.</Typography>

      <Typography variant="body1">
        Capitalized terms shall have the meanings set forth in this Section 1 or
        as otherwise defined in this Agreement.
      </Typography>

      <Typography variant="body1">
        “Account Data” means any information provided by or on behalf of a
        customer or any Authorized User relating to an identified or
        identifiable natural person.
      </Typography>

      <Typography variant="body1">
        “Affiliate” means any entity that directly or indirectly Controls, is
        Controlled by, or is under common Control with the subject entity.
      </Typography>

      <Typography variant="body1">
        “Authorized User” means an individual employee, director, officer,
        executive or other member of Customer&#8217;s organization based in the
        U.S. or other location designated by Bowery that Customer has expressly
        authorized to use and access the applicable Bowery Services.
      </Typography>

      <Typography variant="body1">
        “Change in Control” means (a) the sale or disposition by an entity of
        all or substantially all the entity&#8217;s assets in one or a series of
        related transactions; (b) the merger, consolidation or acquisition of a
        party with, by or into another corporation, entity or person; or (c) any
        change in the ownership of more than fifty percent (50%) of the voting
        securities or other ownership interests of a party.
      </Typography>

      <Typography variant="body1">
        “Control” means direct or indirect ownership or control of more than
        fifty percent (50%) of the equity or ownership interests of the subject
        entity.
      </Typography>

      <Typography variant="body1">
        “Customer” means the individual accepting the terms of this Agreement on
        their own behalf or on behalf of an entity by checking the box
        indicating acceptance of this Agreement.{' '}
      </Typography>

      <Typography variant="body1">
        “Customer Data” means electronic data and information, including Account
        Data, submitted to Bowery Services by or on behalf of Customer or any
        Authorized User, excluding Bowery Services.
      </Typography>

      <Typography variant="body1">
        “Developments” means all ideas, know-how, inventions, methods, or
        techniques developed or conceived as a result of providing Bowery
        Services hereunder, including without limitation any derivative works,
        improvements, enhancements or extensions made to Bowery Services and all
        Intellectual Property Rights therein and thereto throughout the world.
      </Typography>

      <Typography variant="body1">
        “Documentation” means any of Bowery&#8217;s proprietary documentation
        made available to Customer and its Authorized Users, if any, by or on
        behalf of Bowery for use with Bowery Services, including any such
        documentation made available online, electronically, in print or
        otherwise.
      </Typography>

      <Typography variant="body1">
        “Effective Date” means the date Customer accepts the terms of this
        Agreement by checking the box indicating acceptance of this Agreement.
      </Typography>

      <Typography variant="body1">
        “Export Data” means any data that is made available to Customer or any
        of its Authorized Users for download through the Bowery Web Application
        for use pursuant to the terms of this Agreement.
      </Typography>

      <Typography variant="body1">
        “Free Trial Period” means the period specified by Bowery for a free
        trial of the Bowery Web Application commencing on the Effective Date.
      </Typography>

      <Typography variant="body1">
        “Intellectual Property Rights” means all rights in any patents,
        copyrights, mask work, rights of publicity, trademarks (whether
        registered or unregistered), trade dress and service marks, goodwill,
        trade secrets, software, databases, rights in Confidential Information
        and all other intellectual property and property rights that may now
        exist or hereafter come into existence, and all applications therefor
        and registrations, renewals and extensions thereof, under the laws of
        any state, country, territory or other jurisdiction.
      </Typography>

      <Typography variant="body1">
        “Bowery Data” means any data, content or other information provided or
        made available by or on behalf of Bowery in connection with the
        provision or use of, or access to, Bowery Services, including without
        limitation Export Data.
      </Typography>

      <Typography variant="body1">
        “Bowery Web Application” means Bowery&#8217;s hosted application
        providing the Bowery Data and all related software (in object or source
        code form), applications, script, code, structural hierarchies,
        interfaces, networks, equipment, processes, HTML code, graphics,
        multimedia files or text contained therein, together with any fixes,
        updates, enhancements and upgrades thereto.
      </Typography>

      <Typography variant="body1">
        “Bowery Report” means any Bowery Data provided to Customer via flat file
        or other method of transfer outside of the Bowery Web Application.
      </Typography>

      <Typography variant="body1">
        “Bowery Services” means (a) the Bowery Web Application, (b) Bowery
        Report, (c) any other services provided by Bowery and (d) any support
        and professional services to which Customer subscribes.
      </Typography>

      <Typography variant="body1">
        “Usage Data” means technical logs, account and login data, data and
        learnings about Customer&#8217;s use of Bowery Services (e.g., frequency
        of logins, errors, use of certain features, volume of Customer Data
        collected). For clarity, Usage Data does not include Customer Data.
      </Typography>

      <Typography variant="h6">
        2. ACCESS AND USE OF BOWERY SERVICES.
      </Typography>

      <Typography variant="body1">
        2.1 Access and Use Rights. Subject to the terms and conditions of this
        Agreement, Bowery grants Customer and its Authorized Users a limited,
        revocable non-exclusive, non-transferable license to access and use
        Bowery Services set forth in the during the Term solely for
        Customer&#8217;s internal business purposes and in a professional manner
        in accordance with any applicable Documentation.
      </Typography>

      <Typography variant="body1">
        2.2 No Third-Party Access. For the avoidance of doubt, neither Customer
        nor its Authorized Users (if any) may permit any of its or their
        third-party vendors, service providers, contractors or other agents to
        access or use Bowery Services, even if such access or use is for
        Customer&#8217;s internal business purposes.
      </Typography>

      <Typography variant="body1">
        2.3 Third Party Products. Customers may choose to use products and
        services not provided by Bowery (“Third Party Products”) with the Bowery
        Services and in doing so grants Bowery permission to interoperate with
        the Third-Party Products as directed by Customer or the Third-Party
        Products. Unless specified, : (a) Bowery does not warrant or support
        Third Party Products, (b) as between Bowery and Customer, Customer
        assumes all responsibility for the Third Party Products and any
        disclosure, modification or deletion of Customer Data by the Third Party
        Products and (c) Bowery shall have no liability for, and Customer is not
        relieved of any obligations under the Agreement or entitled to any
        refund, credit, or other compensation due to any unavailability of the
        Third Party Products or any change in the ability of Bowery to
        interoperate with the Third Party Products.
      </Typography>

      <Typography variant="body1">2.4 Subscription.</Typography>

      <Typography variant="body1">
        (i) Account creation. Each Authorized User shall be required to create a
        separate account to access and use the Bowery Web Application. Customer
        shall not permit more than one individual to use the Bowery Web
        Application under a single account, and Customer acknowledges that the
        foregoing rights granted are for the unique individual associated with
        such single account.
      </Typography>

      <Typography variant="body1">
        (ii) Obligations. Customer is solely responsible for the activity that
        occurs under its account, and Customer must keep, and require its
        Authorized Users to keep, its and their account password secure. Each
        Authorized User shall use “strong” passwords (e.g., passwords that use a
        combination of upper- and lower-case letters, numbers and symbols) for
        its account. Customers must notify Bowery immediately of any breach of
        security or unauthorized use of Customer&#8217;s or any of its
        Authorized Users&#8217; accounts. Bowery will not be liable for any
        losses caused by any unauthorized use of Customer&#8217;s account.
        Customer shall ensure that Customer&#8217;s Authorized Users are bound
        by and comply with the terms and conditions of this Agreement.
      </Typography>

      <Typography variant="body1">
        2.5 Restrictions. Customer and its Authorized Users shall not, and shall
        not permit any third party, including any third-party providing services
        or technology to or for the benefit of Customer, to:
      </Typography>

      <Typography variant="body1">
        (a) copy, distribute, attempt to get unauthorized access to, or disclose
        any part of Bowery Services in any medium, including any automated or
        non-automated “scraping” of Bowery Data;
      </Typography>

      <Typography variant="body1">
        (b) use any automated system (including “robots,” “spiders,” and
        “offline readers”) to access Bowery Services in a manner that sends more
        request messages to Bowery servers than a human can reasonably produce
        in the same period of time by using a conventional online web browser;
      </Typography>

      <Typography variant="body1">
        (c) reverse engineer, decompile, disassemble or otherwise attempt to
        access the source code or underlying ideas or algorithms of Bowery
        Services;
      </Typography>

      <Typography variant="body1">
        (d) modify, adapt, reproduce or create derivative works based on Bowery
        Services (including any Bowery Services or Documentation);
      </Typography>

      <Typography variant="body1">
        (e) rent, lease, distribute, sell, resell, assign, license, sublicense
        or otherwise transfer its rights to access or use Bowery Services;
      </Typography>

      <Typography variant="body1">
        (f) remove any proprietary notices from Bowery Services or any other
        Bowery materials furnished or made available hereunder;
      </Typography>

      <Typography variant="body1">
        (h) use Bowery Services to develop any similar or competitive service,
        or other information resource of any kind (print, electronic or
        otherwise) for sale to, distribution to, display to or use by others;
      </Typography>

      <Typography variant="body1">
        (i) use Bowery Services, transmit through or store on the Bowery Web
        Application, in violation of (i) any ethical standards, (ii) any
        third-party right known, or that should have been known, to Customer, or
        (iii) any federal, state, or local law, rule, or regulation;
      </Typography>

      <Typography variant="body1">
        (j) take any action that imposes, or may impose (as determined by Bowery
        in its sole discretion) an unreasonable or disproportionately large load
        on the Bowery infrastructure;
      </Typography>

      <Typography variant="body1">
        (k) upload invalid data, viruses, worms, or other harmful software code,
        files, scripts or agents through Bowery Services;
      </Typography>

      <Typography variant="body1">
        (l) monitor data or traffic without permission, interfere or disrupt
        networks connected to any Bowery Service, or interfere with the ability
        of others to access or use Bowery Services;
      </Typography>

      <Typography variant="body1">
        (m) bypass the measures Bowery may use to prevent or restrict access to
        Bowery Services, including features that prevent or restrict use or
        copying of any Bowery Services or enforce limitations on access to or
        use of Bowery Services;
      </Typography>

      <Typography variant="body1">
        (n) share login information for Bowery Services, allow more than one
        individual to use Bowery Services under a single account pursuant to
        this Agreement;
      </Typography>

      <Typography variant="body1">
        (o) use Bowery Services as a factor in establishing an
        individual&#8217;s eligibility for credit or insurance to be used
        primarily for personal, family or household purposes or employment; or
      </Typography>

      <Typography variant="body1">
        (p) use Bowery Services to obtain unauthorized access to Bowery Data
        that would otherwise have required the Customer to pay additional Fees.
      </Typography>

      <Typography variant="body1">2.6 Free Trial.</Typography>

      <Typography variant="body1">
        (a) Free Trial Period. Each Customer may sign up for one free trial to
        use the Bowery Web Application. Upon acceptance of the Agreement,
        Customer will have access to and use of the Bowery Web Application until
        the earlier of (a) the end of the Free Trial Period, (b) the start of
        any paid subscription for Bowery Services, or (c) termination by Bowery,
        in its sole discretion. For the avoidance of doubt, no Customer may sign
        up for more than one free trial to use the Bowery Web Application.
      </Typography>

      <Typography variant="body1">
        (b) Conversion to Annual Subscription. Upon conversion to a paid
        subscription by submitting payment of the required Fees,
        Customer&#8217;s access to and use of the Bowery Web Application shall
        continue to be governed by this Agreement regardless of whether Customer
        is required to re-consent to the terms of this Agreement.{' '}
      </Typography>

      <Typography variant="body1">
        (c) Limitations. The following provision only applies to Customers
        granted access to a free trial to use the Bowery Web Application:
        CUSTOMER UNDERSTANDS AND HEREBY AGREES THAT IF CUSTOMER DOES NOT UPGRADE
        INTO A FEE-PAYING SUBSCRIPTION FOR THE BOWERY WEB APPLICATION ON OR
        PRIOR TO THE LAST DAY OF THE FREE TRIAL PERIOD, ANY AND ALL ACCESS
        RIGHTS TO THE BOWERY WEB APPLICATION WILL TERMINATE AS OF THE END OF
        SUCH FREE TRIAL PERIOD AND ANY AND ALL CUSTOMER DATA WILL NO LONGER BE
        ACCESSIBLE BY CUSTOMER. BOWERY RESERVES THE RIGHT TO TERMINATE
        CUSTOMER&#8217;s FREE TRIAL PERIOD AT ANY TIME FOR ANY REASON IN
        BOWERY&#8217;s SOLE DISCRETION.
      </Typography>

      <Typography variant="h6">3. PROPRIETARY RIGHTS.</Typography>

      <Typography variant="body1">3.1 Customer Proprietary Rights.</Typography>

      <Typography variant="body1">
        (a) Ownership. Except for the rights granted herein, as between Customer
        and Bowery, Customer owns all legal rights, title and interest in and to
        the Customer Data and all Intellectual Property Rights therein.
      </Typography>

      <Typography variant="body1">
        (b) License and Usage. Notwithstanding anything in this Agreement to the
        contrary, during the Term, Customer hereby grants Bowery a non-exclusive
        right and license to Customer Data to copy, use, modify, distribute,
        display and disclose to operate and provide Bowery Services. Bowery may
        aggregate and de-identify Customer Data in connection with providing
        Bowery Services (provided that Customer is not identifiable as the
        source) (“Aggregated Data”) and collect and use Usage Data in order to
        provide Bowery Services to Customer and to monitor, analyze, develop
        upon, maintain, and improve Bowery Services.
      </Typography>

      <Typography variant="body1">3.2 Bowery Proprietary Rights.</Typography>

      <Typography variant="body1">
        (a) Ownership. Except for the rights granted herein, as between Customer
        and Bowery, Bowery and its licensors own all legal right, title and
        interest in and to (i) Bowery Services (including the Bowery
        website(s)), (ii) Aggregated Data, (iii) Usage Data, (iv) the
        Developments (including Developments resulting from any work performed
        to customize any Bowery Service for Customer), and all Intellectual
        Property Rights in each of the foregoing (“Bowery Intellectual
        Property”). Nothing in this Agreement transfers or conveys to the
        Customer any ownership interest in or to the Bowery Intellectual
        Property.
      </Typography>

      <Typography variant="body1">
        (b) Feedback. Customer hereby assigns to Bowery all right, title and
        interest in and to all Customers&#8217; suggestions, ideas, feedback,
        reports, error identifications or other information related to the
        operation of Bowery Services or Customer&#8217;s use and evaluation
        thereof, provided that none of the foregoing contain any Customer Data
        (collectively, “Feedback”), including all Intellectual Property Rights
        therein, and shall assist Bowery in perfecting such rights and obtaining
        assignments of such rights from all individuals involved in generating
        the Feedback.
      </Typography>

      <Typography variant="h6">4. BOWERY OBLIGATIONS.</Typography>

      <Typography variant="body1">
        4.1 Personnel. Bowery will be responsible for the performance of its
        personnel (including employees and contractors) in compliance with the
        Agreement.
      </Typography>

      <Typography variant="body1">
        4.2 Security. Customer acknowledges and agrees that Bowery&#8217;s
        collection, storage, use, and disclosure of Customer Data is governed by
        the{' '}
        <a
          target="_blank"
          href="https://www.boweryvaluation.com/privacy-policy#:~:text=We%20may%20use%20your%20Personal,with%20marketing%20or%20promotional%20material.&amp;text=Cookies%20are%20files%20with%20small,on%20your%20computer's%20hard%20drive."
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        , which is incorporated herein by reference. Notwithstanding the
        foregoing, Bowery does not warrant that Bowery Services are completely
        free from all bugs, errors, or omissions, or will ensure complete
        security.
      </Typography>

      <Typography variant="h6">5. CUSTOMER OBLIGATIONS.</Typography>

      <Typography variant="body1">
        5.1 Hardware. Customer is solely responsible for obtaining and
        maintaining all computer hardware, software and communications equipment
        needed to access and use Bowery Services, and for paying all third-party
        fees and access charges (e.g., ISP, telecommunications) incurred by or
        on behalf of Customer while using any Bowery Service.
      </Typography>

      <Typography variant="body1">
        5.2 Conduct. Customer represents and warrants that Customer and its
        Authorized Users (if any):
      </Typography>

      <Typography variant="body1">
        (a) will abide by all applicable law and governmental regulations
        applicable to Customer&#8217;s (and its Authorized Users&#8217;) use of
        Bowery Services;
      </Typography>

      <Typography variant="body1">
        (b) have all necessary rights to provide and use any Customer Data
        provided or made available to Bowery in connection with Customer&#8217;s
        use of any Bowery Services and that Bowery&#8217;s use thereof as
        contemplated by this Agreement will not violate any right of any third
        party or any law, rule, or regulation; and
      </Typography>

      <Typography variant="body1">
        (c) will not provide any Customer Data in violation of any fiduciary
        duty, duty of confidentiality, or contractual obligation;
      </Typography>

      <Typography variant="body1">
        5.3 Customer Data. Customer is solely responsible for any Customer Data
        and other information that Customer (or any of its Authorized Users)
        makes available to Bowery and to the Bowery Web Application and that the
        Bowery Web Application is a passive conduit. Customer shall notify
        Bowery if Customer becomes aware that any Bowery Service is being used
        for any illegal or unauthorized purpose.
      </Typography>

      <Typography variant="body1">
        5.4 Audit. During the Term and for a period of not less than three (3)
        years after the termination or expiration of this Agreement, if Bowery
        has reasonable grounds to believe that Customer is in violation of its
        obligations hereunder, upon reasonable notice and during usual business
        hours, Customer agrees to allow Bowery, or its designated third party
        (under confidentiality provisions no less stringent than those set forth
        in this Agreement), to audit those relevant facilities, systems,
        procedures, records, or data logs, of Customer, as necessary to ensure
        Customers (and its Authorized Users&#8217;) compliance with the terms of
        this Agreement. Customers will reasonably cooperate with Bowery in such
        an audit and will promptly make available to Bowery all information and
        materials reasonably required by Bowery to conduct such an audit. In the
        event that any audit by Bowery or its designee reveals a material issue
        of noncompliance with this Agreement, Customer will bear (and if
        applicable, shall reimburse Bowery for) all reasonable costs and
        expenses of such audit(s) or, at Bowery&#8217;s option, add such amount
        to future payments due to Bowery.
      </Typography>

      <Typography variant="h6">6. TRACKING TECHNOLOGY.</Typography>

      <Typography variant="body1">
        6.1 Tracking. Bowery uses various tracking technologies to record user
        sessions. This is to monitor how the Website is used, identify issues
        with the Website, monitor the security of the Website, and for internal
        analytical purposes
      </Typography>

      <Typography variant="h6">7. FEES AND TAXES.</Typography>

      <Typography variant="body1">
        7.1 Fees. In consideration for the provision of Bowery Services to
        Customer, Customer shall pay Bowery the subscription fees applicable.{' '}
      </Typography>

      <Typography variant="body1">
        7.2 Payment. Customers will make all payments of Fees within thirty (30)
        days of receipt of Bowery&#8217;s invoice, and all Fees are stated and
        payable in US dollars. Payment obligations for use of Bowery Services
        are non-cancelable and Fees paid are non-refundable.
      </Typography>

      <Typography variant="body1">
        7.3 Payment Card Authorization. If Customer provides its payment by way
        of credit card or debit card, Customer hereby grants permission to
        Bowery to charge all Fees due and owing to such credit card or debit
        card, including Fees with respect to renewals of subscriptions for a
        Bowery Service in accordance with this Agreement on the date on which
        such Fees are due. Customer represents that it is the card holder of any
        credit card or debit card that it provides to Bowery for payment(s), or
        that Customer is duly authorized to provide the consent to use such
        credit card or debit card.
      </Typography>

      <Typography variant="body1">
        7.4 Taxes. All Fees are exclusive of all taxes, levies or duties, and
        Customers will be responsible for payment of such taxes, levies or
        duties resulting from its use of Bowery Services, excluding only federal
        and state taxes based solely upon Bowery&#8217;s net income. If Bowery
        has the legal obligation to pay or collect taxes for which Customer is
        responsible pursuant to this Section 7.4, Bowery will invoice the amount
        of such taxes to Customer and Customer shall pay such amount, unless
        Customer provides Bowery with a valid tax exemption certificate
        authorized by the appropriate taxing authority.
      </Typography>

      <Typography variant="h6">8. TERM; TERMINATION; SUSPENSION.</Typography>

      <Typography variant="body1">
        8.1 Term of Agreement. This Agreement shall commence as of the Effective
        Date and continues until the end of the Free Trial Period or
        subscriptions hereunder have expired or been terminated.
      </Typography>

      <Typography variant="body1">
        8.2 Termination Rights. In the event that Bowery elects to terminate
        this Agreement for cause in accordance with this Section 8.3, Customer
        will not be entitled to any refund for Fees paid prior to the effective
        date of termination, regardless of the remaining duration of the Term.
      </Typography>

      <Typography variant="body1">
        (a) Termination by Either Party. Either party may terminate this
        Agreement for a material breach by the other party that remains uncured
        for thirty (30) days after its receipt of written notice of such breach
        from the non-breaching party.
      </Typography>

      <Typography variant="body1">(b) Termination by Bowery.</Typography>

      <Typography variant="body1">
        (i) Immediate Termination for Customer&#8217;s Breach of Certain Rights.
        Bowery may immediately terminate this Agreement, in the event of any
        breach or alleged breach by Customer of Sections 2 (Access and Use of
        Bowery Services) or 3.2 (Bowery Proprietary Rights).
      </Typography>

      <Typography variant="body1">
        (ii) Termination for Customer&#8217;s Change of Control. Bowery may
        immediately terminate this Agreement upon written notice to Customer if
        Customer is a party to a Change in Control in connection with any of
        Bowery&#8217;s competitors, as determined in Bowery&#8217;s sole
        discretion.
      </Typography>

      <Typography variant="body1">
        (iii) Termination for Applicable Law, Data Supplier, or Privacy Concern.
        Upon reasonable notice to Customer, Bowery may withdraw or decline to
        provide any Bowery Services to Customer in order to comply with any
        requirements imposed by any third party data suppliers, applicable law,
        or in the event Customer&#8217;s use of Bowery Services is the subject
        of a substantial, adverse or documented consumer reaction related to
        consumer privacy issues. In the event that there is any change in any of
        the foregoing that (1) renders fulfillment of an Subscription illegal;
        (2) causes a substantial reduction in Bowery&#8217;s reasonably expected
        economic benefits; or (3) substantially or commercially unreasonably
        increases the burden of Bowery&#8217;s performance, Bowery may terminate
        the applicable Subscription upon thirty (30) days prior written notice
        to Customer, without any further liability for Fees, other than for
        amounts due to Bowery for Bowery Services provided through the effective
        date of termination.
      </Typography>

      <Typography variant="body1">
        (c) Termination of a Bowery Service by Customer
      </Typography>

      <Typography variant="body1">
        (i) Early termination. Customers would only be allowed to terminate at
        the end of their billing period.{' '}
      </Typography>

      <Typography variant="body1">8.3 Suspension Rights.</Typography>

      <Typography variant="body1">
        (a) Material Breach. If Customer has not cured a material breach within
        the applicable cure period, then Bowery may, immediately with written
        notice to Customer, in its sole discretion, and without prejudice to its
        other rights, until such breach has been cured in full, suspend
        performance of some or all of Bowery&#8217;s obligations to provide
        Bowery Services under this Agreement.
      </Typography>

      <Typography variant="body1">
        (b) Threat. If Bowery or Bowery&#8217;s third party data suppliers,
        acting reasonably in the circumstances then known to Bowery, determines
        that Customer&#8217;s or any of its Authorized Users&#8217; use of
        Bowery Services poses an imminent threat to the security or integrity of
        (i) any Customer Data or the data of any other Bowery customer, or (ii)
        the Bowery Data or provision of Bowery Services to Customer or any other
        Bowery customer (collectively, a “Threat”), then Bowery may suspend
        Customer&#8217;s and its Authorized Users&#8217; use of Bowery Services
        until the Threat is resolved and Bowery is able to restore Bowery
        Services for Customer. The parties will endeavor to diligently resolve
        the issue.
      </Typography>

      <Typography variant="body1">
        (c) Customer Obligations. Any such suspension or disconnection under
        this Section{' '}
      </Typography>

      <Typography variant="body1">
        8.4 shall be without liability to Bowery, and Customers will remain
        responsible for all recurring Fees incurred during the period of
        suspension or disconnection.
      </Typography>

      <Typography variant="body1">
        8.4 Effect of Termination. Upon the expiration or earlier termination of
        this Agreement for any reason, whether by Customer or Bowery: (a) all
        licenses granted to Customer hereunder shall terminate and Customer will
        have no rights to access or use any portion of Bowery Service, including
        any Documentation or Bowery Data; (b) Customer shall immediately cease
        accessing and using Bowery Service; and (c) Customer will comply with
        Section 9.6 (Destruction or Return of Confidential Information).
      </Typography>

      <Typography variant="h6">9. CONFIDENTIALITY.</Typography>

      <Typography variant="body1">
        9.1 Definition. Each of the parties shall maintain in confidence any
        non-public, confidential, or proprietary information disclosed by or on
        behalf of the other party, whether disclosed before or during the Term,
        whether disclosed orally or disclosed or accessed in written, electronic
        or any other form or media, whether tangible or intangible, and whether
        or not marked, designated, or otherwise identified as “confidential”
        (“Confidential Information”). Bowery&#8217;s “Confidential Information”
        includes, without limitation, Bowery Services (including without
        limitation the Documentation and Bowery Data), the terms of this
        Agreement and any negotiations between Customer and Bowery regarding use
        of Bowery Services (including, without limitation, any Fees payable
        thereunder). For the avoidance of doubt, Usage Data shall constitute
        Bowery&#8217;s Confidential Information.
      </Typography>

      <Typography variant="body1">
        9.2 Nondisclosure. The receiving party (and Customer&#8217;s Authorized
        Users if Customer is the receiving party) shall not disclose, use,
        transmit, inform or make available to any third party any Confidential
        Information of the disclosing party, and shall not use any Confidential
        Information of the other party except as necessary in order to perform
        its obligations or exercise its rights under this Agreement. Each party
        shall take all actions as are reasonably necessary and appropriate to
        preserve and protect the Confidential Information of the other party and
        such other party&#8217;s respective rights therein, at all times
        exercising at least the same degree of care that it uses to protect its
        own Confidential Information of a similar nature, but in no event less
        than a reasonable degree of care.
      </Typography>

      <Typography variant="body1">
        9.3 Exclusions. Except with respect to Bowery Data and Account Data, the
        restrictions and obligations in this section shall not apply to any
        information that is (a) already rightfully known to the receiving party
        at the time of the disclosure; (b) publicly known at the time of the
        disclosure or becomes publicly known through no wrongful act or failure
        of the receiving party; (c) subsequently disclosed to the receiving
        party on a non-confidential basis by a third party not having a
        confidential relationship with the other party that rightfully acquired
        such information; or (d) communicated to a third party with the express
        written consent of the disclosing party.
      </Typography>

      <Typography variant="body1">
        9.4 Legally Required Disclosure. A disclosure of Confidential
        Information that is legally compelled to be disclosed pursuant to
        applicable law, a subpoena, summons, order or other judicial or
        governmental process shall not be considered a breach of this Agreement;
        provided the receiving party provides prompt notice of any such
        subpoena, order, or the like to the disclosing party so that such party
        will have the opportunity to obtain a protective order or otherwise
        oppose the disclosure.
      </Typography>

      <Typography variant="body1">
        9.5 Data Protection Agreement. To the extent the parties execute a Data
        Protection Agreement (“DPA”) due to the processing of Personal Data (as
        defined in the DPA) which may be contained in Customer Data or Customer
        Confidential Information that is processed by Bowery in its provision of
        Bowery Services, the terms of such DPA shall be incorporated into this
        Agreement by reference.
      </Typography>

      <Typography variant="body1">
        9.6 Destruction or Return of Confidential Information. Upon expiration
        of the Free Trial Period or Term, or earlier termination of this
        Agreement for any reason, the receiving party shall, upon request of the
        disclosing party, destroy (with written certification of the same), all
        copies of the disclosing party&#8217;s Confidential Information, except
        for archival and back-up copies on back-up tapes and if, and to the
        extent, the receiving party is required to retain such material under
        applicable laws, rules or regulations.
      </Typography>

      <Typography variant="h6">10. UPDATES.</Typography>

      <Typography variant="body1">
        10.1 Bowery Data Updates. Bowery Data provided to Customers may be
        updated on an ongoing basis and provided according to the criteria used
        to define the scope of Bowery Services. Customer understands and
        acknowledges that the contents of Bowery Data will change over time as
        the data is updated, and that at any given time, Customer has a right to
        access and use the Bowery Data to which it is subscribed as it exists at
        that time. Certain portions of Bowery Services may be provided by
        Bowery&#8217;s third-party licensors, and Bowery&#8217;s ability to
        provide such information may be subject to the willingness of such
        licensors to continue to contract with Bowery.
      </Typography>

      <Typography variant="h6">11. WARRANTY; DISCLAIMER.</Typography>

      <Typography variant="body1">
        11.1 Warranty. Bowery warrants solely to Customer that Bowery Services
        will materially conform to the description set forth in this Agreement
        under normal use and circumstances when used consistently in accordance
        with the terms of this Agreement. As Bowery&#8217;s sole and exclusive
        liability and Customer&#8217;s sole and exclusive remedy for any breach
        of the warranty set forth in this Section 11.1, Bowery will use
        commercially reasonable efforts to modify Bowery Services to correct the
        non-conformity.
      </Typography>

      <Typography variant="body1">
        11.2 Disclaimer. EXCEPT AS EXPRESSLY PROVIDED BY SECTION 11.1, CUSTOMER
        ACKNOWLEDGES THAT BOWERY SERVICES ARE PROVIDED ON AN “AS IS” AND “AS
        AVAILABLE” BASIS. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,
        BOWERY, ITS LICENSORS AND SUPPLIERS EXPRESSLY DISCLAIM ALL, AND MAKE NO
        WARRANTIES OF ANY KIND (WHETHER EXPRESS, STATUTORY, IMPLIED OR OTHERWISE
        ARISING IN LAW OR FROM A COURSE OF DEALING OR USAGE OF TRADE) WITH
        RESPECT TO BOWERY SERVICES, INCLUDING, WITHOUT LIMITATION, THE
        CONDITIONS OR WARRANTIES OF MERCHANTABILITY, MERCHANTABLE QUALITY,
        FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR LOSS OF DATA.
        BOWERY FURTHER EXPRESSLY DISCLAIMS ANY AND ALL LIABILITY ARISING OUT OF
        OR RELATING TO ANY THIRD-PARTY SITES LINKED TO FROM BOWERY SERVICES.
        BOWERY DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED IN BOWERY SERVICES
        WILL MEET CUSTOMER&#8217;s REQUIREMENTS OR THAT THE OPERATION OF BOWERY
        SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE OR THAT DEFECTS WILL BE
        CORRECTED. BOWERY NEITHER ENDORSES THE CONTENTS OF ANY CUSTOMER DATA NOR
        ASSUMES ANY RESPONSIBILITY FOR ANY INFRINGEMENT OF THIRD-PARTY RIGHTS
        ARISING THEREFROM OR ANY CRIME FACILITATED THEREBY. EXCEPT AS OTHERWISE
        AGREED TO BY BOWERY, BOWERY IS NOT OBLIGATED TO PROVIDE CUSTOMER WITH
        ANY UPDATES TO BOWERY SERVICES BUT MAY ELECT TO DO SO IN ITS SOLE
        DISCRETION.
      </Typography>

      <Typography variant="h6">12. INDEMNIFICATION.</Typography>

      <Typography variant="body1">
        12.1 User shall indemnify, hold harmless, and defend Bowery and its
        officers, directors, employees, agents, affiliates, successors and
        permitted assigns (collectively, “Indemnified Party”) against any and
        all losses, damages, liabilities, deficiencies, claims, actions,
        judgments, settlements, interest, awards, penalties, fines, costs, or
        expenses of whatever kind, including reasonable attorneys&#8217; fees,
        that are incurred by an Indemnified Party arising out of any third-party
        claim (a) alleging breach or non-fulfillment of any representation,
        warranty or covenant under this Agreement by User or its Authorized
        Users (including use of a Bowery Service in violation of this
        Agreement), (b) alleging any grossly negligent or more culpable act or
        omission of User or its Authorized Users, including any reckless or
        willful misconduct, in connection with the performance of its
        obligations under this Agreement, or (c) relating to, or arising out of,
        the use or provisioning of any User Data, Usage Data or Bowery Data.
      </Typography>

      <Typography variant="h6">13. LIMITATION OF LIABILITY.</Typography>

      <Typography variant="body1">
        13.1 Limitation of Liability. IN NO EVENT SHALL THE AGGREGATE LIABILITY
        OF BOWERY, TOGETHER WITH ALL OF ITS AFFILIATES, ARISING OUT OF OR
        RELATED TO THIS AGREEMENT EXCEED (A) THE GREATER OF TOTAL AMOUNT PAID BY
        CUSTOMER AND ITS AFFILIATES HEREUNDER FOR THE SERVICES GIVING RISE TO
        THE LIABILITY IN THE TWELVE (12) MONTHS PRECEDING THE FIRST INCIDENT OUT
        OF WHICH THE LIABILITY AROSE OR (B) ONE THOUSAND DOLLARS ($1,000). THE
        FOREGOING LIMITATION WILL APPLY WHETHER AN ACTION IS IN CONTRACT OR TORT
        AND REGARDLESS OF THE THEORY OF LIABILITY.
      </Typography>

      <Typography variant="body1">
        13.2 Limitation on Consequential Damages. UNDER NO CIRCUMSTANCES SHALL
        BOWERY, OR ITS LICENSORS BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
        SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, (INCLUDING, WITHOUT
        LIMITATION, DAMAGES FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION,
        LOSS OF BUSINESS INFORMATION AND THE LIKE) ARISING OUT OF OR RELATING TO
        THIS AGREEMENT, INCLUDING THE USE OR INABILITY TO USE BOWERY SERVICES,
        EVEN IF BOWERY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
      </Typography>

      <Typography variant="body1">
        13.3 Exceptions. The limitations set forth in Section 13.1 and 13.2 do
        not apply to Bowery&#8217;s (i) willful misconduct or gross negligence,
        or (ii) liability or loss which may not be limited by applicable law.
      </Typography>

      <Typography variant="body1">
        13.4 General. Each party agrees that these exclusions and limitations
        shall apply even if this Agreement or any limited remedy specified
        herein is found to have failed of its essential purpose. These
        limitations are integral to the calculation of fees in connection with
        the license of Bowery Services, and were Bowery to assume any further
        liability, such consideration would out of necessity have been
        substantially increased.
      </Typography>

      <Typography variant="h6">14. COMMUNICATIONS CONSENT.</Typography>

      <Typography variant="body1">
        Customer agrees that Bowery may contact Customer via email, telephone or
        text messages using the contact information provided by you or on your
        behalf in connection with your use of Bowery Services, including for
        marketing purposes. Customers also understand that you may opt out of
        receiving such marketing communications from Bowery at any time by
        contacting support@boweryvaluation.com . If Customers do not choose to
        opt out, Bowery may contact you as described herein.
      </Typography>

      <Typography variant="h6">15. GENERAL.</Typography>

      <Typography variant="body1">
        15.1 Remedies. Customer acknowledges and agrees that Bowery Services
        have been developed at significant cost and have significant commercial
        value to Bowery, and that Bowery may suffer irreparable harm as a result
        of any breach of this Agreement for which monetary damages would not be
        an adequate remedy. Therefore, in addition to all other remedies
        available at law or otherwise (which Bowery does not waive by the
        exercise of any rights hereunder), Bowery will be entitled to seek
        specific performance and injunctive and other equitable relief as a
        remedy for any such breach or threatened breach, and Customer hereby
        waives any requirement for the securing or posting of any bond or the
        showing of actual monetary damages in connection with such claim.
      </Typography>

      <Typography variant="body1">
        15.2 Publicity. Except for Bowery&#8217;s reference to Customer as one
        of its customers and using Customer&#8217;s logo as part of such
        reference, neither party shall use the other party&#8217;s name, marks,
        or any other proprietary identifying symbols, or issue any press
        release.
      </Typography>

      <Typography variant="body1">
        15.3. Modifications to this Agreement. Bowery may modify the terms and
        conditions of this Agreement (which may include changes to Bowery
        Services pricing and plans) from time to time with notice.
      </Typography>

      <Typography variant="body1">
        (a) Free Trial. If Customer has a Free Trial, any modifications will
        become effective immediately, and if Customer objects to the
        modifications, its exclusive remedy is to terminate this Agreement and
        cease using Bowery Services.
      </Typography>

      <Typography variant="body1">
        (b) Paid Subscription. Unless a shorter period is specified by Bowery
        (e.g., due to changes in the law or exigent circumstances), the
        modifications become effective upon renewal of Customer&#8217;s current
        Term.
      </Typography>

      <Typography variant="body1">
        15.4 Notices. All notices or reports permitted or required under this
        Agreement shall be in writing. If to Bowery, notices must be provided to
        the address set forth below in this Section 15.4, and shall be deemed to
        have been received (i) if given by hand, immediately upon receipt; (ii)
        if given by overnight courier service, the first business day following
        dispatch or (iii) if given by registered or certified mail, postage
        prepaid and return receipt requested, the second business day after such
        notice is deposited in the mail. If to Customer, Bowery may provide
        notice to Customer&#8217;s email address on file or through Bowery
        Services and such notices shall be deemed to have been received upon
        delivery. Either party may update its address with notice to the other
        party.
      </Typography>

      <Typography variant="body1">
        Notice to Bowery:
        <br />
        Bowery Real Estate Systems Inc.
        <br />
        61-63 Crosby St, 3rd Floor
        <br />
        New York, NY 10012
        <br />
        support@boweryvaluation.com
      </Typography>

      <Typography variant="body1">
        15.5 Assignment. Neither party may assign this Agreement, or any of its
        rights of obligations hereunder, without the other party&#8217;s prior
        written consent, provided, however, that Bowery may assign this
        Agreement, in whole or in part, without Customer&#8217;s prior written
        consent (a) to one of its affiliates, or (b) to any entity that acquires
        (whether by merger, reorganization, acquisition, sale or otherwise) all
        or substantially all of the business or assets of Bowery to which this
        Agreement relates. Any assignment made in violation with this Section
        15.5 shall be void, and this Agreement shall benefit and bind the
        permitted successors and assigns of the parties.
      </Typography>

      <Typography variant="body1">
        15.6 Relationship of Parties. Bowery and Customer&#8217;s relationship
        is solely that of independent contractors, and this Agreement will not
        establish any partnership, joint venture, employment, franchise or
        agency between Bowery and Customer. Neither Bowery nor Customer will
        have the power to bind the other or incur obligations on the
        other&#8217;s behalf without the other&#8217;s prior written consent.
      </Typography>

      <Typography variant="body1">
        15.7 Governing Law; Dispute Resolution; Arbitration
      </Typography>

      <Typography variant="body1">
        (a) Governing Law. This Agreement shall be governed by the laws of the
        State of New York, excluding its conflict of laws rules. The United
        Nations Convention for the International Sale of Goods is excluded in
        its entirety from this Agreement.
      </Typography>

      <Typography variant="body1">
        (b) Dispute Resolution. Customer agrees that in the event of any
        dispute, controversy or claim (each, a “Claim”) between Customer and
        Bowery, Customer will first contact Bowery and make a good faith
        sustained effort to resolve the dispute before resorting to more formal
        means of resolution, including without limitation any court action.
      </Typography>

      <Typography variant="body1">(c) Arbitration Agreement.</Typography>

      <Typography variant="body1">
        (i) After the informal dispute resolution process, any remaining Claims
        relating in any way to Customer&#8217;s use of Bowery Services, or
        relating in any way to the communications between Customer and Bowery or
        any other user of Bowery Services, will be finally resolved by binding
        arbitration. This mandatory arbitration agreement applies equally to
        Customer and Bowery. However, this arbitration agreement does not govern
        any Claim by Bowery for infringement of its Intellectual Property Rights
        or use of or access to a Bowery Service that is unauthorized or exceeds
        authorization granted in this Agreement.
      </Typography>

      <Typography variant="body1">
        (ii) Arbitration is more informal than a lawsuit in court. There is no
        judge or jury in arbitration. Instead, the Claim is resolved by a
        neutral arbitrator. Court review of an arbitration award is limited.
        Except to the extent the parties agree otherwise, arbitrators can award
        the same damages and relief that a court can award. Customer agrees that
        the U.S. Federal Arbitration Act governs the interpretation and
        enforcement of this provision, and that Customer and Bowery are each
        waiving the right to a trial by jury or to participate in a class
        action. This arbitration provision will survive any termination of this
        Agreement.
      </Typography>

      <Typography variant="body1">
        (iii) If Customer wishes to begin an arbitration proceeding, after
        following the informal dispute resolution procedure set forth in Section
        15.7(b), Customer must send a letter requesting arbitration and
        describing its claim to 61 - 63 Crosby Street, Floor 3, New York, NY
        10012. The arbitration will be administered by the American Arbitration
        Association (AAA) under its rules including, if Customer is an
        individual, the AAA&#8217;s Supplementary Procedures for
        Consumer-Related Disputes. If Customer has accessed or used Bowery
        Services on behalf of an entity, the AAA&#8217;s Commercial Arbitration
        Rules will be used. The AAA&#8217;s rules are available at{' '}
        <a target="_blank" href="https://www.adr.org/rules" rel="noreferrer">
          https://www.adr.org/rules
        </a>{' '}
        or by calling 1-800-778-7879.
      </Typography>

      <Typography variant="body1">
        (iv) The number of arbitrators will be one; provided such arbitrator has
        at least ten years&#8217; experience in commercial litigation in the
        software industry. Customers may choose to have the arbitration
        conducted by telephone, based on written submissions, or in person in
        the county of residence or at another mutually agreed location. The
        arbitration will be conducted in the English language. New York law will
        apply. Judgment on the award rendered by the arbitrator may be entered
        in any court having jurisdiction thereof.
      </Typography>

      <Typography variant="body1">
        (v) Payment of all filing, administration and arbitrator fees will be
        governed by the AAA&#8217;s rules. If Customer is an individual and has
        not accessed or used Bowery Services on behalf of an entity, Bowery will
        reimburse those fees for claims totaling less than $10,000, unless the
        arbitrator determines the claims are frivolous, and Bowery will not seek
        attorneys&#8217; fees and costs in arbitration unless the arbitrator
        determines the claims are frivolous.
      </Typography>

      <Typography variant="body1">
        (vi) The arbitrator, and not any federal, state, or local court, will
        have exclusive authority to resolve any Claim relating to the
        interpretation, applicability, unconscionability, arbitrability,
        enforceability, or formation of this arbitration agreement, including
        any claim that all or any part of this arbitration agreement is void or
        voidable. However, the preceding sentence will not apply to Section
        15.7(d) (Class Action Waiver) below.
      </Typography>

      <Typography variant="body1">
        (vii) If Customer does not want to arbitrate Claims with Bowery and
        Customer is an individual, Customer may opt out of this arbitration
        agreement by sending an email to support@boweryvaluation.com within
        thirty (30) days of the date on which Customer first accesses or uses a
        Bowery Service.
      </Typography>

      <Typography variant="body1">(d) Class Action Waiver</Typography>

      <Typography variant="body1">
        (i) Any Claim must be brought in the respective party&#8217;s individual
        capacity, and not as a plaintiff or class member in any purported class,
        collective, representative, multiple plaintiffs, or similar proceeding
        (“Class Action”). The parties expressly waive any ability to maintain
        any Class Action in any forum. If the Claim is subject to arbitration,
        the arbitrator will not have authority to combine or aggregate similar
        claims or conduct any Class Action nor make an award to any person or
        entity not a party to the arbitration. Any claim that all or part of
        this Class Action Waiver is unenforceable, unconscionable, void, or
        voidable may be determined only by a court of competent jurisdiction and
        not by an arbitrator.
      </Typography>

      <Typography variant="body1">
        (ii) If this class action waiver is found to be unenforceable, then the
        entirety of the Arbitration Agreement, if otherwise effective, will be
        null and void. The arbitrator may award declaratory or injunctive relief
        only in favor of the individual party seeking relief and only to the
        extent necessary to provide relief warranted by that party&#8217;s
        individual claim. If for any reason a claim proceeds in court rather
        than in arbitration, Customer and Bowery each waive any right to a jury
        trial.
      </Typography>

      <Typography variant="body1">
        15.8 Waiver. Any waiver of the provisions of this Agreement or of a
        party&#8217;s rights or remedies under this Agreement must be in writing
        to be effective. Failure, neglect, or delay by a party to enforce the
        provisions of this Agreement or its rights or remedies at any time, will
        not be construed and will not be deemed to be a waiver of such
        party&#8217;s rights under this Agreement and will not in any way affect
        the validity of the whole or any part of this Agreement or prejudice
        such party&#8217;s right to take subsequent action. Except as expressly
        stated in this Agreement, no exercise or enforcement by either party of
        any right or remedy under this Agreement will preclude the enforcement
        by such party of any other right or remedy under this Agreement or that
        such party is entitled by law to enforce.
      </Typography>

      <Typography variant="body1">
        15.9 Severability. If any provision of this Agreement is held invalid,
        unenforceable, or void by a court of competent jurisdiction, such
        provision will be enforced to the maximum extent permissible, and such
        holding will not affect the remaining terms. The invalid, unenforceable,
        or void provision will be deemed amended or replaced by a valid, legal,
        and enforceable provision that matches the intent of the original
        language as closely as possible.
      </Typography>

      <Typography variant="body1">
        15.10 Force Majeure. Except for payment obligations, neither party shall
        be in default if its failure to perform any obligation under this
        Agreement is caused by supervening conditions beyond that party&#8217;s
        reasonable control, including acts of God, civil commotion, war,
        strikes, labor disputes, third-party Internet service interruptions or
        slowdowns, vandalism, or “hacker” attacks, acts of terrorism or
        governmental demands or requirements.
      </Typography>
    </Container>
  );
};
