import { observer } from 'mobx-react-lite';
import { useRootStore } from 'stores/RootStore';
import { Terms } from 'components/login/Terms';
import { SecondaryPage } from 'components/SecondaryPage';

const TermsPage = observer(() => {
  const { routeStore } = useRootStore();
  const { route, setRoute } = routeStore;

  return (
    <SecondaryPage
      escapeKeyBack={true}
      route={route}
      setRoute={setRoute}
      backRoute="login"
      renderBody={() => <Terms />}
    />
  );
});

export { TermsPage };
