import { CssBaseline } from '@mui/material';
import { AdminPage } from 'components/admin/AdminPage';
import { ContactPage } from 'components/contact/ContactPage';
import { LoginPage } from 'components/login/LoginPage';
import { TermsPage } from 'components/login/TermsPage';
import { SearchPage } from 'components/SearchPage';
import { SettingsPage } from 'components/settings/SettingsPage';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'stores/RootStore';

const App = observer(() => {
  const { authStore, mapStore, routeStore } = useRootStore();
  const { route, setRoute } = routeStore;
  if (!route) return null;

  return (
    <>
      <CssBaseline />
      {route === 'login' ? (
        <LoginPage setRoute={setRoute} authStore={authStore} />
      ) : route === 'loginContact' ? (
        <ContactPage />
      ) : route === 'terms' ? (
        <TermsPage />
      ) : route === 'contact' ? (
        <ContactPage />
      ) : route === 'search' ? (
        <SearchPage mapStore={mapStore} authStore={authStore} />
      ) : ['createUser', 'editUsers', 'editUser', 'jobDetails'].includes(
          route,
        ) ? (
        <AdminPage />
      ) : ['payments', 'paymentsSuccess', 'changePassword'].includes(route) ? (
        <SettingsPage />
      ) : null}
    </>
  );
});

export { App };
