import { IconName, LeafletMarkerProps } from 'components/shared/LeafletMarker';
import { MapStore } from 'stores/MapStore';
import { useMemo } from 'react';
import { JobOutput } from 'dtos/JobOutput';
import { LeafletMap } from '../shared/LeafletMap';
import numbro from 'numbro';
import { observer } from 'mobx-react-lite';
import { ReactComponent as SearchResult } from 'images/searchResult.svg';

type MapProps = {
  style?: any;
  mapStore: MapStore;
  cluster: boolean;
  displayUnits: 'sf' | 'units';
};

const getTooltip = (job: JobOutput, displayUnits: 'sf' | 'units'): string => {
  return displayUnits === 'sf'
    ? numbro(job.appraisalInformation.valuePerSFBucket).formatCurrency({
        thousandSeparated: true,
      }) + '/SF'
    : numbro(job.appraisalInformation.valuePerUnitBucket).formatCurrency({
        thousandSeparated: true,
      }) + '/unit';
};

const getIcon = (job: JobOutput, selectedBoweryId: string | null): IconName => {
  return selectedBoweryId === job.boweryId ? 'jobSelected' : 'job';
};

const Map = observer(
  ({ style = {}, mapStore, cluster, displayUnits }: MapProps) => {
    const {
      setBounds,
      selectBoweryIdFromMap,
      selectedBoweryId,
      results,
      center,
      zoom,
      lastSearchCoords,
      searchValue,
    } = mapStore;

    const { markers, selectedMarker } = useMemo(() => {
      let selectedMarker = undefined;
      const markers = results.reduce(
        (prev: LeafletMarkerProps[], current: JobOutput) => {
          const marker = {
            id: current.boweryId,
            alt: `Bowery ID ${current.boweryId}`,
            icon: getIcon(current, selectedBoweryId),
            position: {
              lat: current.address.coordinates.latitude || 0,
              lng: current.address.coordinates.longitude || 0,
            },
            tooltip: getTooltip(current, displayUnits),
            onClick: selectBoweryIdFromMap,
            displayUnits,
          };
          if (current.boweryId === selectedBoweryId) {
            selectedMarker = marker;
          } else {
            prev.push(marker);
          }
          return prev;
        },
        [],
      );
      return { markers, selectedMarker };
    }, [results, displayUnits, selectBoweryIdFromMap, selectedBoweryId]);

    const searchMarker = useMemo(() => {
      return lastSearchCoords
        ? {
            id: `searchmarker`,
            alt: 'Search Result',
            icon: 'search' as IconName,
            position: {
              lat: lastSearchCoords.latitude,
              lng: lastSearchCoords.longitude,
            },
            tooltip: (
              <div>
                <SearchResult
                  style={{ marginRight: 6, position: 'relative', top: 1 }}
                />
                {searchValue?.description}
              </div>
            ),
            displayUnits,
          }
        : undefined;
    }, [lastSearchCoords, searchValue, displayUnits]);

    return (
      <div data-testid="map" style={{ ...style }}>
        <LeafletMap
          zoom={zoom}
          center={center}
          cluster={cluster}
          onSetBounds={setBounds}
          onClick={() => selectBoweryIdFromMap(null)}
          markers={markers}
          selectedMarker={selectedMarker}
          searchMarker={searchMarker}
          searchCoords={lastSearchCoords}
        />
      </div>
    );
  },
);

export { Map };
