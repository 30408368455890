export const filters = {
  searchAddress: 'Search Address',
  propertyType: 'Property Type',
  buildingType: 'Building Type',
  appraisedWithin: 'Date of Value',
  units: 'Units',
  grossBuildingArea: 'GBA',
  yearBuilt: 'Year Built',
  recentlyRenovated: 'Renovation Status',
  capRate: 'Cap Rate',
  displayUnits: 'Display In',
};

export const createUser = {
  username: 'Username',
  password: 'Password',
  confirmPassword: 'Confirm Password',
  email: 'Email Address',
  plan: 'Plan',
  planExpiration: 'Plan Expiration',
  planRenews: 'Plan Renews After Expiration',
  planProducts: 'Products',
  paymentCustomerId: 'Stripe Customer ID',
  enabled: 'Enabled',
  admin: 'Admin',
};

export const editUser = {
  ...createUser,
  password: 'Change Password',
};

export const changePassword = {
  oldPassword: 'Current Password',
  newPassword: 'New Password',
  confirmPassword: 'Confirm New Password',
};

export const login = {
  username: 'Username',
  password: 'Password',
};
