import Navigo, { Match } from 'navigo';
import { makeAutoObservable } from 'mobx';

/*
 * Makes current route and route name observable,
 * and enforces type on route name.
 */
export class RouteStore<RouteName> {
  constructor(router: Navigo) {
    this.router = router;
    makeAutoObservable(this, {}, { autoBind: true });

    this.router.hooks({
      after: (match) => {
        this.setMatch(match);
      },
    });
  }

  router: Navigo;

  match: Match | null = null;

  setMatch(match: Match) {
    this.match = match;
  }

  setRoute = (route: RouteName, params: any = null) => {
    const result = this.router.navigateByName(route as string, params);
    if (!result) throw new Error('Route not found');
  };

  get route(): RouteName | null {
    return this.match ? (this.match.route.name as RouteName) : null;
  }
}
