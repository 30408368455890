import Form from 'mobx-react-form';
import { muiFormBindings } from 'forms/utils/muiFormBindings';
import { plugins } from 'forms/utils/formPlugins';
import { FieldsDefinitions } from 'mobx-react-form/lib/models/FormInterface';
import { login } from 'utils/labels';

const fields: FieldsDefinitions = [
  {
    name: 'username',
    label: login.username,
    bindings: 'TextField',
    value: '',
    rules: 'required',
  },
  {
    name: 'password',
    label: login.password,
    bindings: 'TextField',
    type: 'password',
    value: '',
    rules: 'required',
  },
];

const hooks = {};

const formOptions = {
  validationDebounceWait: 0,
  validateOnChange: true,
  showErrorsOnReset: false,
};

export function createForm() {
  return new Form(
    { fields },
    { plugins, hooks, bindings: muiFormBindings, options: formOptions },
  );
}
