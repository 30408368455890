import {
  computed,
  IReactionDisposer,
  makeAutoObservable,
  reaction,
  runInAction,
  when,
} from 'mobx';
import { debounce } from 'throttle-debounce';
import { createForm } from 'forms/filtersForm';
import { JobOutput } from 'dtos/JobOutput';
import { JobSearchInput } from 'dtos/JobSearchInput';
import moment from 'moment';
import { AuthStore } from 'stores/AuthStore';
import {
  getNumberFromPercentString,
  getNumberSearchRange,
} from 'utils/converters';
import { PlaceType } from 'components/shared/GoogleAutoComplete';
import { JobSearchOutput } from 'dtos/JobSearchOutput';
import { initialCoordinates, initialZoom } from 'utils/config';
import { Match } from 'navigo';

export type Point = {
  latitude: number;
  longitude: number;
};
export type Bounds = Point[];

const searchDelay = 300;

export class MapStore {
  private dispose: IReactionDisposer[] = [];
  private authStore: AuthStore;

  filtersForm = createForm();

  status: 'loading' | 'loaded' = 'loaded';
  results: JobOutput[] = [];

  bounds: Bounds = [];
  zoom = initialZoom;
  center: Point = initialCoordinates;

  lastResultsSeq = 0;
  lastSearchAttemptSeq = 0; // for testing

  lastSearchCoords: Point | null = null;
  searchValue: PlaceType | null = null;
  searchInputValue = '';

  selectedBoweryId: string | null = null;
  selectedBoweryIdFrom: 'map' | 'results' = 'map';

  constructor({ authStore }: { authStore: AuthStore }) {
    this.authStore = authStore;
    makeAutoObservable(
      this,
      {
        // avoids re-querying when irrelevant form fields like displayUnits change
        searchQuery: computed.struct,
      },
      { autoBind: true },
    );

    this.dispose.push(
      reaction(
        () => this.authStore.status === 'valid' && this.searchQuery,
        (query: any) => {
          if (query) this.search(query);
        },
      ),
    );
    if (this.authStore.status === 'valid') {
      this.search(this.searchQuery);
    }
  }

  destroy() {
    this.dispose.map((fn) => fn());
  }

  activate(match: Match) {}

  get searchQuery(): JobSearchInput {
    const filters = this.filtersForm.values();
    const query: JobSearchInput = {};
    if (this.bounds.length) {
      query.geolocationPolygon = [...this.bounds, this.bounds[0]].map(
        (point) => [point.longitude, point.latitude],
      );
    }
    query.propertyType = filters.propertyType;
    query.buildingType = filters.buildingType;
    if (filters.appraisedWithin && filters.appraisedWithin !== 'all') {
      const dateCount = Number(filters.appraisedWithin.slice(0, -1));
      const dateItem = filters.appraisedWithin.slice(-1);
      const dateItemMapped = (
        {
          y: 'years',
          m: 'months',
        } as any
      )[dateItem];
      if (!isNaN(dateCount) && dateItemMapped) {
        query.appraisalDate = {
          min: moment
            .utc()
            .subtract(dateCount, dateItemMapped)
            .format('YYYY-MM-DD'),
        };
      }
    }
    query.unitsBucket = filters.units.map((value: string) =>
      getNumberSearchRange(value),
    );
    query.grossBuildingAreaBucket = filters.grossBuildingArea.map(
      (value: string) => getNumberSearchRange(value),
    );
    if (
      filters.recentlyRenovated.includes('no') !==
      filters.recentlyRenovated.includes('yes')
    ) {
      query.recentlyRenovated = filters.recentlyRenovated.includes('yes');
    }
    query.yearBuiltBucket = filters.yearBuilt.map((value: string) =>
      getNumberSearchRange(value),
    );
    query.capRate = {
      min: getNumberFromPercentString(filters.capRate.min),
      max: getNumberFromPercentString(filters.capRate.max),
    };
    return query;
  }

  search = debounce(searchDelay, async (query: any) => {
    const newResultsSeq = ++this.lastResultsSeq;
    try {
      this.status = 'loading';
      const response = await this.authStore.fetchWithAuth(
        `${process.env.REACT_APP_SERVER_URI}/jobs/search`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(query),
        },
      );
      if (!response.ok) throw new Error();
      const { jobs }: JobSearchOutput = await response.json();
      runInAction(() => {
        this.status = 'loaded';
        if (this.lastResultsSeq === newResultsSeq) {
          this.results = jobs;
        }
        this.lastSearchAttemptSeq++;
      });
    } catch (err) {
      runInAction(() => {
        this.lastSearchAttemptSeq++;
      });
    }
  });

  setBounds(bounds: Bounds) {
    this.bounds = bounds;
  }

  searchCoords(point: Point | null) {
    this.lastSearchCoords = point;
  }

  resetForm() {
    this.filtersForm.reset();
    this.setSearchValue(null);
    this.setSearchInputValue('');
    this.searchCoords(null);
    this.selectBoweryIdFromMap(null);
  }

  setSearchValue = (newValue: PlaceType | null) => {
    this.searchValue = newValue;
  };

  setSearchInputValue = (newInputValue: string) => {
    this.searchInputValue = newInputValue;
  };

  selectBoweryIdFromMap = (boweryId: string | null) => {
    this.selectedBoweryId = boweryId;
    this.selectedBoweryIdFrom = 'map';
  };

  selectBoweryIdFromResults = (boweryId: string | null) => {
    this.selectedBoweryId = boweryId;
    this.selectedBoweryIdFrom = 'results';
  };

  attemptedSearch = (): Promise<void> => {
    const lastSearchAttemptSeq = this.lastSearchAttemptSeq;
    return when(() => this.lastSearchAttemptSeq !== lastSearchAttemptSeq);
  };
}
