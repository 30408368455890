import { observer } from 'mobx-react-lite';
import { useRootStore } from 'stores/RootStore';
import { ChangePassword } from 'components/settings/ChangePassword';
import { SecondaryPage } from 'components/SecondaryPage';
import { Payments } from 'components/settings/Payments';

const SettingsPage = observer(() => {
  const { routeStore, changePasswordStore, paymentsStore, authStore } =
    useRootStore();
  const { route, setRoute } = routeStore;
  if (!route) return null;

  return (
    <SecondaryPage
      route={route}
      setRoute={setRoute}
      backRoute="search"
      renderBody={() => (
        <>
          {route === 'changePassword' && (
            <ChangePassword changePasswordStore={changePasswordStore} />
          )}
          {['payments', 'paymentsSuccess'].includes(route) && (
            <Payments authStore={authStore} paymentsStore={paymentsStore} />
          )}
        </>
      )}
    />
  );
});

export { SettingsPage };
