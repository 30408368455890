import { makeAutoObservable, runInAction } from 'mobx';
import * as FullStory from '@fullstory/browser';
import { LoginOutput } from 'dtos/LoginOutput';
import { UserOutput } from 'dtos/UserOutput';
import { createForm } from 'forms/loginForm';
import { Plan, Role } from 'dtos/common';
import * as moment from 'moment';
import { planExpirationWarnDays } from 'utils/config';
import { identifyFeatureFlaggerUser } from '@bowery-valuation/feature-flagger-client';

export const TOKEN_STORAGE_KEY = 'token';

type LoginStatus = 'unknown' | 'loading' | 'valid' | 'invalid';

export class AuthStore {
  accessToken: string | null = null;
  currentUser: UserOutput | null = null;

  status: LoginStatus = 'unknown';
  error: string | null = null;
  form = createForm();

  constructor() {
    this.accessToken = window.localStorage.getItem(TOKEN_STORAGE_KEY);
    makeAutoObservable(this, {}, { autoBind: true });
  }

  login = async () => {
    try {
      this.status = 'loading';
      this.error = null;
      const { username, password } = this.form.values();
      const query = { username, password };
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URI}/auth/login`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(query),
        },
      );
      if (!response.ok) throw new Error('Not logged in');
      const results: LoginOutput = await response.json();
      const { access_token, user } = results;
      this.form.reset();
      window.localStorage.setItem(TOKEN_STORAGE_KEY, access_token);
      await this.identifyWithExternalSystems(user);
      runInAction(() => {
        this.accessToken = access_token;
        this.status = 'valid';
        this.currentUser = user;
      });
    } catch (err) {
      runInAction(() => {
        this.status = 'invalid';
        this.error = 'Unable to log in. Please try again.';
        this.currentUser = null;
      });
    }
  };

  checkAuth = async () => {
    try {
      this.status = 'loading';
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URI}/users/current`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: this.getAuthHeader(),
          },
        },
      );
      if (!response.ok) throw new Error('Not logged in');
      const user: UserOutput = await response.json();
      await this.identifyWithExternalSystems(user);
      runInAction(() => {
        this.status = 'valid';
        this.currentUser = user;
      });
    } catch (err) {
      window.localStorage.removeItem(TOKEN_STORAGE_KEY);
      runInAction(() => {
        this.accessToken = null;
        this.status = 'invalid';
        this.currentUser = null;
      });
      await this.unidentifyWithExternalSystems();
    }
  };

  refreshUser = async () => {
    try {
      const response = await this.fetchWithAuth(
        `${process.env.REACT_APP_SERVER_URI}/users/current`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      if (!response.ok) throw new Error('Not logged in');
      const user: UserOutput = await response.json();
      runInAction(() => {
        this.currentUser = user;
      });
    } catch (err) {}
  };

  async logout() {
    window.localStorage.removeItem(TOKEN_STORAGE_KEY);
    this.accessToken = null;
    this.status = 'invalid';
    this.currentUser = null;
    await this.unidentifyWithExternalSystems();
  }

  private async identifyWithExternalSystems(user: UserOutput) {
    const { username, email } = user;

    if (process.env.REACT_APP_FULLSTORY_ORG_ID) {
      FullStory.identify(username, { email });
    }

    if (process.env.REACT_APP_FRESHCHAT_TOKEN) {
      const fcWidget = (window as any).fcWidget;
      if (fcWidget) {
        try {
          fcWidget.user.setFirstName(username);
          fcWidget.user.setEmail(email);
        } catch (e) {
          console.log('freshchat error', e);
        }
      }
    }

    if (process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID) {
      await identifyFeatureFlaggerUser(username);
    }
  }

  private async unidentifyWithExternalSystems() {
    if (process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID) {
      await identifyFeatureFlaggerUser(null);
    }
  }

  async fetchWithAuth(
    input: RequestInfo | URL,
    init: RequestInit = {},
  ): Promise<Response> {
    if (this.status === 'valid') {
      init.headers = {
        ...init.headers,
        Authorization: this.getAuthHeader(),
      };
    }
    const response = await fetch(input, init);
    this.checkResponse(response);
    return response;
  }

  checkResponse(response: Response) {
    // 401 indicates an actual session expiration,
    // and 403 indicates that the user has become disabled
    if (
      this.status === 'valid' &&
      (response.status === 401 || response.status === 403)
    ) {
      this.error = 'Your session has expired.';
      this.status = 'invalid';
    }
  }

  getAuthHeader() {
    return `Bearer ${this.accessToken}`;
  }

  setStatus(status: LoginStatus) {
    return (this.status = status);
  }

  get isLoggedIn() {
    return this.status === 'valid';
  }

  get isAdmin() {
    return this.isLoggedIn && !!this.currentUser?.roles.includes(Role.ADMIN);
  }

  get warnPlanExpiring() {
    if (planExpirationWarnDays === null) return false;
    if (!this.isLoggedIn || this.isAdmin || !this.currentUser) return false;
    const { plan, planRenews, planExpiration, planExpired } = this.currentUser;
    if (!plan || planExpired) return false;
    if (plan === Plan.MONTHLY && planRenews) return false;
    const expirationMoment = moment.utc(planExpiration);
    return moment
      .utc()
      .add(planExpirationWarnDays, 'days')
      .isAfter(expirationMoment);
  }

  get planExpiringInDays(): number | null {
    if (!this.isLoggedIn || this.isAdmin || !this.currentUser) return null;
    const { plan, planRenews, planExpiration, planExpired } = this.currentUser;
    if (!plan) return null;
    if (planExpired) return 0;
    if (plan === Plan.MONTHLY && planRenews) return null;
    const expirationMoment = moment.utc(planExpiration);
    return Math.max(1, expirationMoment.diff(moment.utc(), 'days'));
  }
}
