import {
  Button,
  Card,
  CardActions,
  CardContent,
  styled,
  Typography,
} from '@mui/material';
import { Plan } from 'dtos/common';
import { UserOutput } from 'dtos/UserOutput';
import { usePaymentLinks } from 'components/usePaymentLinks';

const OverlayBlurred = styled('div')({
  position: 'absolute',
  zIndex: 1000,
  inset: 0,
  backdropFilter: 'blur(10px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

type ExpiredOverlayProps = {
  user: UserOutput;
};

const ExpiredOverlay = ({ user }: ExpiredOverlayProps) => {
  const { disableButtons, renderMenu, handleClickSignup } = usePaymentLinks();
  const { plan } = user;

  return (
    <OverlayBlurred>
      <Card elevation={24} sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography variant="h6" component="div">
            {plan === Plan.MONTHLY ? 'Plan Expired' : 'Trial Expired'}
          </Typography>
          <Typography variant="body1" sx={{ marginTop: '28px' }}>
            {plan === Plan.MONTHLY ? 'Your monthly plan ' : 'Your free trial '}
            has expired. To continue using Sizer,{' '}
            {plan === Plan.MONTHLY ? 'renew' : 'upgrade'} your plan.
          </Typography>
        </CardContent>
        <CardActions sx={{ justifyContent: 'end', padding: '16px' }}>
          <Button
            variant="contained"
            disabled={disableButtons}
            onClick={handleClickSignup}
          >
            {plan === Plan.MONTHLY ? 'Renew Plan' : 'Upgrade Plan'}
          </Button>
          {renderMenu()}
        </CardActions>
      </Card>
    </OverlayBlurred>
  );
};

export { ExpiredOverlay };
