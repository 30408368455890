import { PaymentManageInput } from 'dtos/PaymentManageInput';
import { PaymentManageOutput } from 'dtos/PaymentManageOutput';
import { PaymentStartInput } from 'dtos/PaymentStartInput';
import { PaymentStartOutput } from 'dtos/PaymentStartOutput';
import { makeAutoObservable, runInAction } from 'mobx';
import { AuthStore } from 'stores/AuthStore';
import { RouteName } from 'stores/RootStore';
import { Match } from 'navigo';
import { Product } from 'dtos/common';

export class PaymentsStore {
  private authStore: AuthStore;
  private setRoute: (route: RouteName) => void;

  message = '';
  hasError: boolean = false;

  constructor({
    authStore,
    setRoute,
  }: {
    authStore: AuthStore;
    setRoute: (route: RouteName) => void;
  }) {
    this.authStore = authStore;
    this.setRoute = setRoute;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async activate(match: Match) {
    this.message = '';
    this.hasError = false;
    this.refreshPaymentStatus();
  }

  async activatePaymentSuccess(match: Match) {
    const sessionId = match.params?.sessionId;
    if (sessionId) {
      // Not doing anything with this currently
      this.setRoute('paymentsSuccess');
    } else {
      this.message = 'Your plan is now active.';
      this.hasError = false;
      this.refreshPaymentStatus();
    }
  }

  async startPayment(products: Product[], cancelUrl = '/payments') {
    try {
      this.message = '';
      this.hasError = false;
      const dto: PaymentStartInput = {
        successUrl: `${process.env.REACT_APP_CLIENT_URI}/payments/success?sessionId={CHECKOUT_SESSION_ID}`,
        cancelUrl: `${process.env.REACT_APP_CLIENT_URI}${cancelUrl}`,
        products,
      };
      const response = await this.authStore.fetchWithAuth(
        `${process.env.REACT_APP_SERVER_URI}/payments/start`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dto),
        },
      );
      if (!response.ok) throw new Error('Error starting payment');
      const { redirect }: PaymentStartOutput = await response.json();
      if (redirect) document.location = redirect;
    } catch (err) {
      runInAction(() => {
        this.message = 'There was an error starting your payment.';
        this.hasError = true;
      });
    }
  }

  async managePayments(returnUrl = '/payments') {
    try {
      this.message = '';
      this.hasError = false;
      const dto: PaymentManageInput = {
        returnUrl: `${process.env.REACT_APP_CLIENT_URI}${returnUrl}`,
      };
      const response = await this.authStore.fetchWithAuth(
        `${process.env.REACT_APP_SERVER_URI}/payments/manage`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dto),
        },
      );
      if (!response.ok) throw new Error('Error accessing payments');
      const { redirect }: PaymentManageOutput = await response.json();
      if (redirect) document.location = redirect;
    } catch (err) {
      runInAction(() => {
        this.message = 'There was an error accessing your payments.';
        this.hasError = true;
      });
    }
  }

  async refreshPaymentStatus() {
    await this.authStore.refreshUser();
  }
}
