import { Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { useFeatureFlag } from '@bowery-valuation/feature-flagger-client';
import { Plan, Product } from 'dtos/common';
import { useRootStore } from 'stores/RootStore';
import { defaultProducts, featureFlags } from 'utils/config';
import { formatProduct } from 'utils/formatters';

export const usePaymentLinks = () => {
  const allowPayments = useFeatureFlag(featureFlags.payments);
  const multipleProducts = useFeatureFlag(featureFlags.multipleProducts);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [disableButtons, setDisableButtons] = useState(false);
  const menuOpen = Boolean(anchorEl);

  const { authStore, paymentsStore, routeStore } = useRootStore();
  const { setRoute } = routeStore;
  const { startPayment, managePayments } = paymentsStore;

  const handleClickSignup = (event: React.MouseEvent<HTMLElement>) => {
    const { isLoggedIn, currentUser } = authStore;
    if (!isLoggedIn || !currentUser) return;
    const { plan, planExpired } = currentUser!;

    if (!allowPayments) {
      setRoute('contact');
    } else if (plan === Plan.MONTHLY && !planExpired) {
      setDisableButtons(true);
      managePayments(document.location.pathname);
    } else if (multipleProducts) {
      setAnchorEl(event.currentTarget);
    } else {
      setDisableButtons(true);
      startPayment(defaultProducts, document.location.pathname);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (products: Product[]) => {
    setAnchorEl(null);
    setDisableButtons(true);
    startPayment(products);
  };

  const handleClickManage = () => {
    setDisableButtons(true);
    managePayments(document.location.pathname);
  };

  const availableProducts: Product[] = Object.values(Product);

  const renderMenu = () => (
    <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}>
      {availableProducts.map((product) => (
        <MenuItem key={product} onClick={() => handleMenuItemClick([product])}>
          {formatProduct(product)}
        </MenuItem>
      ))}
    </Menu>
  );

  return {
    disableButtons,
    renderMenu,
    handleClickSignup,
    handleClickManage,
  };
};
