import { Map } from 'components/map/Map';
import { Results } from 'components/map/Results';
import { MapStore } from 'stores/MapStore';
import { observer } from 'mobx-react-lite';

type MapAndResultsProps = {
  style?: any;
  mapStore: MapStore;
  cluster?: boolean;
};

const MapAndResults = observer(
  ({ style, mapStore, cluster = true }: MapAndResultsProps) => {
    const displayUnits = mapStore.filtersForm.$('displayUnits').get('value');

    return (
      <div
        aria-label="map page"
        style={{
          ...style,
          display: 'flex',
          borderTop: '1px solid rgba(224, 224, 224, 1)',
        }}
      >
        <Map
          style={{ flex: 1 }}
          mapStore={mapStore}
          displayUnits={displayUnits}
          cluster={cluster}
        />
        <Results mapStore={mapStore} style={{ flex: 1, minWidth: 850 }} />
      </div>
    );
  },
);

export { MapAndResults };
