import {
  MenuItem,
  MenuItemProps,
  Select,
  SelectProps,
  Tooltip,
} from '@mui/material';

type CustomSelectProps = {
  items: ({
    key?: any;
    value: any;
    text: string;
  } & MenuItemProps)[];
  value: any[];
} & SelectProps;

const CustomSelect = ({ value, items, ...selectProps }: CustomSelectProps) => {
  const renderValue = (value: any) => {
    if (value === '') return '';
    if (!Array.isArray(value)) value = [value];
    const selectedItems = items.filter((item) => value.includes(item.value));
    const rendered = selectedItems.map((item) => item.text).join(', ');
    return (
      <Tooltip placement="top-start" title={rendered}>
        <span>{rendered}</span>
      </Tooltip>
    );
  };

  return (
    <Select value={value} renderValue={renderValue} {...selectProps}>
      {items.map(({ key, value: itemValue, text, ...itemProps }) => (
        <MenuItem key={key ?? itemValue} value={itemValue} {...itemProps}>
          {text}
        </MenuItem>
      ))}
    </Select>
  );
};

export { CustomSelect as Select };
