import Form from 'mobx-react-form';
import { muiFormBindings } from 'forms/utils/muiFormBindings';
import { plugins } from 'forms/utils/formPlugins';
import { FieldsDefinitions } from 'mobx-react-form/lib/models/FormInterface';
import { createUser } from 'utils/labels';

const fields: FieldsDefinitions = [
  {
    name: 'username',
    label: createUser.username,
    bindings: 'TextField',
    value: '',
    rules: 'required|string|between:5,100',
  },
  {
    name: 'password',
    label: createUser.password,
    bindings: 'TextField',
    type: 'password',
    value: '',
    rules: 'required|string|between:8,100',
  },
  {
    name: 'confirmPassword',
    label: createUser.confirmPassword,
    bindings: 'TextField',
    type: 'password',
    value: '',
    rules: 'required|same:password',
  },
  {
    name: 'email',
    label: createUser.email,
    bindings: 'TextField',
    value: '',
    rules: 'required|email|string|between:5,100',
  },
  {
    name: 'plan',
    label: createUser.plan,
    bindings: 'TextField', // not using Select
    value: '',
    rules: 'required|string',
  },
  {
    name: 'planExpiration',
    label: createUser.planExpiration,
    bindings: 'DatePicker',
    value: '',
    rules: 'moment',
  },
  {
    name: 'planRenews',
    label: createUser.planRenews,
    bindings: 'Checkbox',
    value: false,
  },
  {
    name: 'planProducts',
    label: createUser.planProducts,
    bindings: 'Select',
    value: [],
  },
  {
    name: 'paymentCustomerId',
    label: createUser.paymentCustomerId,
    bindings: 'TextField',
    value: '',
    rules: 'string',
  },
  {
    name: 'enabled',
    label: createUser.enabled,
    bindings: 'Checkbox',
    value: true,
  },
  {
    name: 'admin',
    label: createUser.admin,
    bindings: 'Checkbox',
    value: false,
  },
];

const hooks = {};

const formOptions = {
  validationDebounceWait: 0,
  showErrorsOnReset: false,
};

export function createForm() {
  return new Form(
    { fields },
    { plugins, hooks, bindings: muiFormBindings, options: formOptions },
  );
}
