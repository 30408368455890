import 'leaflet/dist/leaflet.css';
import './LeafletMap.css';

import MarkerClusterGroup from 'react-leaflet-cluster';
import { LatLngBounds, LatLng } from 'leaflet';
import { useCallback, useEffect, useMemo } from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import { Bounds, Point } from 'stores/MapStore';
import {
  LeafletMarker,
  LeafletMarkerProps,
} from 'components/shared/LeafletMarker';
import { maxZoom } from 'utils/config';

const mapBoundsToPolygonCoordinates = ({ lat, lng }: LatLng): Point => ({
  latitude: lat,
  longitude: lng,
});

const getMapBoundsCoordinates = (mapBounds: LatLngBounds): Bounds => {
  if (!mapBounds) {
    return [];
  }

  const coords = [
    mapBounds.getNorthWest(),
    mapBounds.getNorthEast(),
    mapBounds.getSouthEast(),
    mapBounds.getSouthWest(),
  ];

  return coords.map(mapBoundsToPolygonCoordinates);
};

type MapEventsProps = {
  onSetBounds?: (bounds: Bounds) => void;
  onClick: () => void;
  searchCoords: Point | null;
  center: Point;
  zoom: number;
};

const MapEvents = ({
  onSetBounds,
  onClick,
  searchCoords,
  center,
  zoom,
}: MapEventsProps) => {
  const map = useMap();

  const onMoveEnd = useCallback(() => {
    const mapBounds = map.getBounds();
    const coords = getMapBoundsCoordinates(mapBounds);
    onSetBounds?.(coords);
  }, [map, onSetBounds]);

  useEffect(() => {
    map.on('click', onClick);
    map.on('moveend', onMoveEnd);
    return () => {
      map.off('moveend', onMoveEnd);
      map.off('click', onClick);
    };
  }, [map, onMoveEnd, onClick]);

  useEffect(() => {
    map.setView(
      {
        lat: center.latitude,
        lng: center.longitude,
      },
      zoom,
    );
  }, [map, center, zoom]);

  useEffect(() => {
    if (searchCoords !== null) {
      map.setView(
        {
          lat: searchCoords.latitude,
          lng: searchCoords.longitude,
        },
        maxZoom,
      );
    }
  }, [map, searchCoords]);

  return null;
};

type LeafletMapProps = {
  onSetBounds?: (bounds: Bounds) => void;
  onClick: () => void;
  markers: LeafletMarkerProps[];
  searchMarker?: LeafletMarkerProps;
  selectedMarker?: LeafletMarkerProps;
  center: Point;
  zoom: number;
  cluster: boolean;
  searchCoords: Point | null;
};

const LeafletMap = ({
  onSetBounds,
  onClick,
  markers,
  searchMarker,
  selectedMarker,
  center,
  zoom,
  cluster,
  searchCoords,
}: LeafletMapProps) => {
  const markerComponents = useMemo(() => {
    return markers.map((marker) => (
      <LeafletMarker key={`map-cluster-${marker.id}`} {...marker} />
    ));
  }, [markers]);

  return (
    <>
      <MapContainer
        center={[center.latitude, center.longitude]}
        zoom={zoom}
        maxZoom={maxZoom}
        style={{ height: '100%' }}
      >
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {cluster ? (
          <MarkerClusterGroup>{markerComponents}</MarkerClusterGroup>
        ) : (
          markerComponents
        )}
        {selectedMarker && <LeafletMarker {...selectedMarker} />}
        {searchMarker && <LeafletMarker {...searchMarker} />}
        <MapEvents
          onSetBounds={onSetBounds}
          onClick={onClick}
          searchCoords={searchCoords}
          center={center}
          zoom={zoom}
        />
      </MapContainer>
    </>
  );
};

export { LeafletMap };
