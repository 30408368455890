import { Product } from 'dtos/common';

// bias address searches toward New York state instead of user location
export const defaultGooglePlaceOptions = {
  componentRestrictions: { country: 'us' },
  bounds: {
    // New York state
    south: 40.4772,
    north: 45.0153,
    west: -79.7624,
    east: -71.7517,
  },
};

// New York City
export const initialCoordinates = {
  latitude: 40.7902029,
  longitude: -73.9425632,
};

export const initialZoom = 13;
export const maxZoom = 16;

export const maxJobResults = 1000;

export const planExpirationWarnDays = 5;

export const defaultProducts: Product[] = [Product.WORLD];

export const featureFlags = {
  payments: 'sizer-payments',
  multipleProducts: 'sizer-multiple-products',
};
